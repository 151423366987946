import { 
    SET_CAMPAIGN_DATA, 
    RESET_CAMPAIGN_DATA,
	SHOW_REQUEST_CALLBACK,
	HIDE_REQUEST_CALLBACK,
	SHOW_IMAGE_MODAL,
	HIDE_IMAGE_MODAL,
	FACEBOOK_PIXEL_LEAD_DISABLE,
	FACEBOOK_PIXEL_LEAD_ENABLE,
} from '../action-types/action.types';

export const setCampaignData = (url) => {
	return (dispatch) => {
		dispatch({
			type: SET_CAMPAIGN_DATA,
            payload: { url }
		});
	};
};

export const resetCampaignData = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_CAMPAIGN_DATA,
		});
	};
};

export const showRequestCallback = () => {
	return (dispatch) => {
		dispatch({
			type: SHOW_REQUEST_CALLBACK,
		});
	};
};

export const disableAutoRequestCallback = () => {
	return (dispatch) => {
		dispatch({
			type: HIDE_REQUEST_CALLBACK,
		});
	};
};

export const showImageModal = (imageData) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_IMAGE_MODAL,
			payload: imageData
		});
	};
};

export const disableImageModal = () => {
	return (dispatch) => {
		dispatch({
			type: HIDE_IMAGE_MODAL,
		});
	};
};

export const disableFacebookPixelLead = () => {
	return (dispatch) => {
		dispatch({
			type:FACEBOOK_PIXEL_LEAD_DISABLE
		})
	}
}

export const enableFacebookPixelLead = () => {
	return (dispatch) => {
		dispatch({
			type:FACEBOOK_PIXEL_LEAD_ENABLE
		})
	}
}