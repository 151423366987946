import {
    SET_ROUTE,
    RESET_ROUTE,
    DESTROY_ROUTE,
    ADD_AUTH_TIMER,
    REMOVE_AUTH_TIMER,
} from '../action-types/action.types';

// To Retain a Route before any action

export const setRoute = (route)=>{
    return dispatch => {
        dispatch({
            type: SET_ROUTE,
            payload: route
        })
    }
}

export const resetRoute = ()=>{
    return dispatch => {
        dispatch({
            type: RESET_ROUTE,
        })
    }
}

export const destroyRoute = ()=>{
    return dispatch => {
        dispatch({
            type: DESTROY_ROUTE,
        })
    }
}

export const addAuthTimer = (timerId) => {
	return dispatch => {
	dispatch({
		type: ADD_AUTH_TIMER,
		payload: timerId
	})
}
}

export const removeAuthTimer = (timerId) => {
	return dispatch => {
		clearTimeout(timerId); // removed previous timer reference
		dispatch({
			type: REMOVE_AUTH_TIMER,
			payload: "",
		})
	}
}