// Loader
export const LOADER_START = 'LOADER_START';
export const LOADER_STOP = 'LOADER_STOP';

// Location
export const SET_CITY_LIST = 'SET_CITY_LIST';
export const SET_LOCATION = 'SET_LOCATION';
export const DESTROY_LOCATION = "DESTROY_LOCATION";

// Route
export const SET_ROUTE = 'SET_ROUTE';
export const RESET_ROUTE = 'RESET_ROUTE';
export const DESTROY_ROUTE = 'DESTROY_ROUTE';
export const ADD_AUTH_TIMER = 'ADD_AUTH_TIMER';
export const REMOVE_AUTH_TIMER = 'REMOVE_AUTH_TIMER';

// Auth
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAIL = 'GET_OTP_FAIL';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_SRL_POINTS = 'UPDATE_USER_SRL_POINTS';
export const UPDATE_USER_SRL_POINTS_CART = 'UPDATE_USER_SRL_POINTS_CART';

// Auth Family
export const GET_FAMILY_OTP_SUCCESS = 'GET_FAMILY_OTP_SUCCESS';
export const GET_FAMILY_OTP_FAIL = 'GET_FAMILY_OTP_FAIL';
export const VERIFY_FAMILY_OTP_SUCCESS = 'VERIFY_FAMILY_OTP_SUCCESS';
export const VERIFY_FAMILY_OTP_FAIL = 'VERIFY_FAMILY_OTP_FAIL';
export const REGISTRATION_FAMILY_SUCCESS = 'REGISTRATION_FAMILY_SUCCESS';
export const REGISTRATION_FAMILY_FAIL = 'REGISTRATION_FAMILY_FAIL';

// User Session Data
export const DESTROY_SESSION  = 'DESTROY_SESSION';

// FEED
export const SAVE_ALL_PACKAGES = 'SAVE_ALL_PACKAGES';
export const SAVE_HOME_PACKAGES_LIST = 'SAVE_HOME_PACKAGES_LIST';
export const SAVE_HOME_TESTS_LIST = "SAVE_HOME_TESTS_LIST";
export const SAVE_HOME_COVID_TESTS_PACKAGES_LIST = 'SAVE_HOME_COVID_TESTS_PACKAGES_LIST';
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";
export const SAVE_SEARCH_FLAG = "SAVE_SEARCH_FLAG";
export const RESET_FEED = "RESET_FEED";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DESTROY_CART = "DESTROY_CART";
export const CART_PRODUCT_CITY_CHECKING = "CART_PRODUCT_CITY_CHECKING";
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export const SET_CART_USERS_LIST = "SET_CART_USERS_LIST";
export const SET_CART_SELECTED_USER = "SET_CART_SELECTED_USER";
export const RESET_CART_SELECTED_USER = "RESET_CART_SELECTED_USER";
export const SET_CART_SOMEONE_ELSE_LIST = "SET_CART_SOMEONE_ELSE_LIST";
export const CLEAR_CART_USER_ALL = "CLEAR_CART_USER_ALL";
export const SET_CART_USER_ADDRESS = "SET_CART_USER_ADDRESS";
export const CART_APPLY_PROMO_CODE = "CART_APPLY_PROMO_CODE";
export const CART_RESET_PROMO_CODE = "CART_RESET_PROMO_CODE";
export const CART_SET_SRL_POINTS = "CART_SET_SRL_POINTS";
export const CART_VERIFY_SRL_POINTS = "CART_VERIFY_SRL_POINTS";
export const CART_RESET_SRL_POINTS = "CART_RESET_SRL_POINTS";
export const CART_EXPIRE_SRL_POINTS = "CART_EXPIRE_SRL_POINTS";
export const SET_CART_PP_TEST_OPTION = "SET_CART_PP_TEST_OPTION";
export const SET_CART_HARD_COPY_OPTION = "SET_CART_HARD_COPY_OPTION";
export const SET_CART_SELECTED_LAB_CENTER = "SET_CART_SELECTED_LAB_CENTER";
export const SET_COMBINED_CART_PRODUCTS = "SET_COMBINED_CART_PRODUCTS";
export const SET_CART_BILL_DETAILS = "SET_CART_BILL_DETAILS";
export const SET_SAMPLE_COLLECTION_TYPE_OPTION = "SET_SAMPLE_COLLECTION_TYPE_OPTION";

// Wishlist
export const GOT_WISHLIST_DATA = "GOT_WISHLIST_DATA";
export const ADD_WISHLIST_DATA = "ADD_WISHLIST_DATA";
export const DELETE_WISHLIST_ITEMS = "DELETE_WISHLIST_ITEMS";
export const DELETE_WISHLIST_DATA = "DELETE_WISHLIST_DATA";
export const DESTROY_WISHLIST = "DESTROY_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const CLEAR_COMPARE = "CLEAR_COMPARE";
export const ADD_COMPARE_PACKAGE = "ADD_COMPARE_PACKAGE";
export const REMOVE_COMPARE_PACKAGE = "REMOVE_COMPARE_PACKAGE";

// Analytics
export const SET_CAMPAIGN_DATA = "SET_CAMPAIGN_DATA";
export const RESET_CAMPAIGN_DATA = "RESET_CAMPAIGN_DATA";
export const SHOW_REQUEST_CALLBACK = "SHOW_REQUEST_CALLBACK";
export const HIDE_REQUEST_CALLBACK = "HIDE_REQUEST_CALLBACK";
export const SHOW_IMAGE_MODAL = "SHOW_IMAGE_MODAL";
export const HIDE_IMAGE_MODAL = "HIDE_IMAGE_MODAL";
// Analytics- Facebook
export const FACEBOOK_PIXEL_LEAD_ENABLE = "FACEBOOK_PIXEL_LEAD_ENABLE";
export const FACEBOOK_PIXEL_LEAD_DISABLE = "FACEBOOK_PIXEL_LEAD_DISABLE";

export const OPT_IN = "OPT_IN";
