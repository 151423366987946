import { 
    GOT_WISHLIST_DATA, 
    ADD_WISHLIST_DATA, 
    DELETE_WISHLIST_DATA, 
    DELETE_WISHLIST_ITEMS, 
    DESTROY_WISHLIST 
} from '../action-types/action.types';

const initialStates = {
	wishlist_data: [],
};

const wishlist = (state = initialStates, action) => {
	switch (action.type) {
		case GOT_WISHLIST_DATA: {
			return {
				wishlist_data: action.payload,
			};
		}
		case ADD_WISHLIST_DATA: {
			const wishlist_data = [...state.wishlist_data];
			wishlist_data.push(action.payload);
			return {
				wishlist_data,
			};
		}
		case DELETE_WISHLIST_DATA: {
			let wishlist_data = [...state.wishlist_data];
			wishlist_data = wishlist_data.filter((item) => item.ID != action.payload);
			return {
				wishlist_data,
			};
		}

		case DELETE_WISHLIST_ITEMS: {
			let wishlist_data = [...state.wishlist_data];
			const ids = action.payload.ids;
			ids.map((id) => wishlist_data = wishlist_data.filter((item) => item.ID != id));
			return {
				wishlist_data,
			};
		}

		case DESTROY_WISHLIST: {
			return {
				wishlist_data: [],
			};
		}

		default:
			return state;
	}
};

export default wishlist;
