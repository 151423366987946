import Texts from './Texts.json';

// export const LOGIN_TIMEOUT_VALUE = 1800000; // 30 minutes
export const LOGIN_TIMEOUT_VALUE = 0; // 0 value;

export const DEFAULT_CITY_ID = '5006';
export const DEFAULT_CITY_NAME = 'Kottayam';
export const DEFAULT_CITY_OBJ = { label: 'Kottayam', value: 5006, city_name: 'KOTTAYAM', state_id: 13, is_default: true };

export const PP_TEST_ID = 100000033; // Postprandial Test

export const FAILED_STATUS = ['TXN_FAILURE', 'FAILURE', 'FALIURE', 'INVALID']; // Payment Failure status options
export const EMPTY_FIELD_LIST = [undefined, null, 'null', 'Null', '']; // Validation options
export const IGNORE_CHAR_LIST = ['-']; // Url city validation

export const MAX_PRESCRIPTION_FILE_SIZE = 26214400; // 25MB i.e 25x1024x1024
export const MAX_PRESCRIPTION_FILE_COUNT = 10;
export const SUPPORTED_PRESCRIPTION_FORMATS = [
	{ mime: 'application/pdf', signature: ['25504446'] },
	{ mime: 'image/jpg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/jpeg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/png', signature: ['89504e47'] },
];

export const MAX_IMAGE_FILE_SIZE = 2000000;
export const SUPPORTED_IMAGE_FORMATS = [
	{ mime: 'image/jpg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/jpeg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/png', signature: ['89504e47'] },
];

export const MAX_VENDOR_FILE_SIZE = 5000000;
export const SUPPORTED_VENDOR_FILE_FORMATS = [
	{ mime: 'application/pdf', signature: ['25504446'] },
	{ mime: 'image/jpg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/jpeg', signature: ['ffd8ffed', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffdb'] },
	{ mime: 'image/png', signature: ['89504e47'] },
];

export const REGEX_DIGITS = /^[0-9]+$/;
export const REGEX_FULL_NAME = /^[a-zA-Z'.]+( [a-zA-Z'.]+)*$/;
export const REGEX_PRESCRIPTION_FORMATS = /(\.pdf|\.jpg|\.jpeg|\.png)$/i;
export const REGEX_VENDOR_FILE_FORMATS = /(\.pdf|\.jpg|\.jpeg|\.png)$/i;
export const REGEX_IMAGE_FORMATS = /(\.jpg|\.jpeg|\.png)$/i;

export const PAGE_ID_COVID_ANTIBODY = 13;
export const PAGE_ID_COVID_DISCLAIMER = 4;
export const PAGE_ID_PRIVACY_POLICY = 3;
export const PAGE_ID_TERMS_AND_CONDITIONS = 5;

export const FORMS = {
	covid: {
		type: '3',
		texts: Texts.forms.covid,
	},
};

export const ACCORDION_TYPES = {
	ACCORDION: 1,
	DESCRIPTION: 2,
};

export const PRODUCT_TYPES = {
	TOP_SELLING_PRODUCTS: 1,
	POPULAR_PREVENTIVE_PRODUCT: 2,
	COVID_CARE_PACKAGES: 3,
};

export const COLLECTION_MODES = [
	{ type: 'H', label: 'Home collection' },
	{ type: 'C', label: 'Collection Center' },
	{ type: 'L', label: 'Lab Visit' },
];

export const GTAG_CONVERSION_ID = 'AW-328836092/YWwaCJO7wJMYEPzH5pwB';
export const GTAG_PURACHASE_CONVERSION_ID = 'AW-328836092/1de9CNqF74MYEPzH5pwB';
export const GTAG_EVENT = 'event';
export const GTAG_EVENT_CLICK = 'click';
export const GTAG_EVENT_ADD_TO_CART = 'add_to_cart';
export const GTAG_EVENT_PURCHASE = 'purchase';
export const GTAG_EVENT_CONVERSION = 'conversion';
export const GTAG_EVENT_LOGIN_SUCCESS = 'login_success';
export const GTAG_EVENT_REGISTER_SUCCESS = 'signup_success';
export const GTAG_EVENT_MEMBER_ADDED = 'member_added';
export const GTAG_EVENT_VIEW_ITEM_LIST  = 'view_item_list';
export const GTAG_EVENT_PRESCRIPTION_SUBMIT = 'prescription_submit';
export const GTAG_EVENT_SELECT_ITEM  = 'select_item';
export const GTAG_EVENT_VIEW_ITEM  = 'view_item';
export const GTAG_EVENT_VIEW_CART  = 'view_cart';
export const GTAG_EVENT_REPORT_DOWNLOADED  = 'report_downloaded';
export const GTAG_EVENT_BEGIN_CHECKOUT  = 'begin_checkout';
export const GTAG_EVENT_PATIENT_ADDED  = 'patient_added';

export const DAYS = [
	{ id: 1, name: 'Mon' },
	{ id: 2, name: 'Tue' },
	{ id: 3, name: 'Wed' },
	{ id: 4, name: 'Thu' },
	{ id: 5, name: 'Fri' },
	{ id: 6, name: 'Sat' },
	{ id: 7, name: 'Sun' },
];

export const DEFAULT_3_COLUMN_SLIDER_SETTINGS = {
	arrows: true,
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 3,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
	],
};

export const DEFAULT_4_COLUMN_SLIDER_SETTINGS = {
	arrows: true,
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 4,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
	],
};

export const SORTING_OPTIONS_PRODUCT = [
	{ value: 'low-to-high', label: 'Price: Low to High' },
	{ value: 'high-to-low', label: 'Price: High to Low' },
	{ value: 'a-to-z', label: 'Title: A to Z' },
	{ value: 'z-to-a', label: 'Title: Z to A' },
];
export const GENDERS_FORM = [
	{ label: 'Male', value: 'M' },
	{ label: 'Female', value: 'F' },
	{ label: 'Other', value: 'U' },
];
export const SALUTATIONS_FORM = [
	{
		label: 'Mr.',
		value: 'Mr.',
	},
	{
		label: 'Mrs.',
		value: 'Mrs.',
	},
	{
		label: 'Master',
		value: 'Master',
	},
	{
		label: 'Ms.',
		value: 'Ms.',
	},
];
export const RELATIONS_FORM = [
	{
		label: 'Brother',
		value: 'B',
	},
	{
		label: 'Sister',
		value: 'S',
	},
	{
		label: 'Father',
		value: 'F',
	},
	{
		label: 'Mother',
		value: 'M',
	},
	{
		label: 'Son',
		value: 'O',
	},
	{
		label: 'Daughter',
		value: 'D',
	},
	{
		label: 'Spouse',
		value: 'P',
	},
];
export const ADDRESS_TYPES_FROM = [
	{
		label: 'Home',
		value: 'Home',
	},
	{
		label: 'Work',
		value: 'Work',
	},
];
