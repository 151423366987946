import Cookies from 'js-cookie';
import AES from 'crypto-js/aes';
import ENC_UTF8 from 'crypto-js/enc-utf8';

export const TEMP = {
	KEY: '_tk',
	PASSWORD: '90590348534YYIU!@00',
	SECRET: 'CQ54jUu2bnqCDShtkyYBjJeDgBLxWrUC4MBRFDbY6angP0HNQAzb9FURKecb6X9T'
};

export const DUMB = 'CQ54jUu2bnqCDShtkyYBjJeDgBLxWrUC4MBRFDbY6angP0HNQAzb9FURKecb6X9T';
export const NTBYIP = 'CQ54jUu2bnqCDShtkyYBjJeDgBLxWrUC4MBRFDbY6angP0HNQAzb9FURKecb6X9T';

export const encryption = (data, secret) => {
	return AES.encrypt(data, secret).toString();
};

export const encryptionStringify = (data, secret) => {
	return AES.encrypt(JSON.stringify(data), secret).toString();
};

export const decryption = (data, secret) => {
	const bytes = AES.decrypt(data, secret);
	return JSON.parse(bytes.toString(ENC_UTF8));
};

export const decryptionText = (data, secret) => {
	const bytes = AES.decrypt(data, secret);
	return bytes.toString(ENC_UTF8);
};

export const setCookie = (key, data) => {
	// Encrypt
	let ciphertext = encryptionStringify(data, TEMP.PASSWORD);
	Cookies.set(key, ciphertext, { secure: true });
};

export const getCookie = (key) => {
	// Decrypt
	let cookie_data = Cookies.get(key);
	if (cookie_data) {
		let decryptedData = decryption(cookie_data, TEMP.PASSWORD);
		return decryptedData;
	} else {
		return null;
	}
};
