// export const cityStateList = [
// 	{
// 		label: 'Agartala (Tripura)',
// 		value: 5,
// 		city_name: 'AGARTALA',
// 		state_id: 25,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Agra (Uttar Pradesh)',
// 		value: 6,
// 		city_name: 'AGRA',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ahmedabad (Gujarat)',
// 		value: 7,
// 		city_name: 'AHMEDABAD',
// 		state_id: 7,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ahmednagar (Maharashtra)',
// 		value: 8,
// 		city_name: 'AHMEDNAGAR',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Aizwal (Mizoram)',
// 		value: 9,
// 		city_name: 'AIZWAL',
// 		state_id: 18,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ajmer (Rajasthan)',
// 		value: 10,
// 		city_name: 'AJMER',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Aliganj (Uttar Pradesh)',
// 		value: 1231,
// 		city_name: 'ALIGANJ',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Aligarh (Uttar Pradesh)',
// 		value: 13,
// 		city_name: 'ALIGARH',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Alwar (Rajasthan)',
// 		value: 16,
// 		city_name: 'ALWAR',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ambala (Haryana)',
// 		value: 17,
// 		city_name: 'AMBALA',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Amritsar (Punjab)',
// 		value: 21,
// 		city_name: 'AMRITSAR',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Asansol (West Bengal)',
// 		value: 31,
// 		city_name: 'ASANSOL',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Aurangabad (Bihar)',
// 		value: 33,
// 		city_name: 'AURANGABAD',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Badaun (Uttar Pradesh)',
// 		value: 965,
// 		city_name: 'BADAUN',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Balaghat (Madhya Pradesh)',
// 		value: 39,
// 		city_name: 'BALAGHAT',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Balasore (Orissa)',
// 		value: 40,
// 		city_name: 'BALASORE',
// 		state_id: 20,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bangalore (Karnataka)',
// 		value: 45,
// 		city_name: 'BANGALORE',
// 		state_id: 12,
// 		aliases: 'Bengaluru',
// 	},
// 	{
// 		label: 'Barasat (West Bengal)',
// 		value: 515,
// 		city_name: 'BARASAT',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bareilly (Uttar Pradesh)',
// 		value: 50,
// 		city_name: 'BAREILLY',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Barrackpore (West Bengal)',
// 		value: 1099,
// 		city_name: 'BARRACKPORE',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Basirhat (West Bengal)',
// 		value: 1104,
// 		city_name: 'BASIRHAT',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Beas (Punjab)',
// 		value: 488,
// 		city_name: 'BEAS',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Begusarai (Bihar)',
// 		value: 604,
// 		city_name: 'BEGUSARAI',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bhagalpur (Bihar)',
// 		value: 60,
// 		city_name: 'BHAGALPUR',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bhatinda (Punjab)',
// 		value: 478,
// 		city_name: 'BHATINDA',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bhavnagar (Gujarat)',
// 		value: 65,
// 		city_name: 'BHAVNAGAR',
// 		state_id: 7,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bhopal (Madhya Pradesh)',
// 		value: 69,
// 		city_name: 'BHOPAL',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bhubaneswar (Orissa)',
// 		value: 70,
// 		city_name: 'BHUBANESWAR',
// 		state_id: 20,
// 		aliases: 'BHUBANESHWAR',
// 	},
// 	{
// 		label: 'Bihar Sharif (Bihar)',
// 		value: 73,
// 		city_name: 'BIHAR SHARIF',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bikaner (Rajasthan)',
// 		value: 76,
// 		city_name: 'BIKANER',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Bilaspur, Chhattisgarh (Chhattisgarh)',
// 		value: 77,
// 		city_name: 'BILASPUR, CHHATTISGARH',
// 		state_id: 5,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Birati (West Bengal)',
// 		value: 1102,
// 		city_name: 'BIRATI',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Burdwan (West Bengal)',
// 		value: 1232,
// 		city_name: 'BURDWAN',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Chandannagar (West Bengal)',
// 		value: 1105,
// 		city_name: 'CHANDANNAGAR',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Chandigarh (Chandigarh)',
// 		value: 95,
// 		city_name: 'CHANDIGARH',
// 		state_id: 48,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Chapra (Bihar)',
// 		value: 96,
// 		city_name: 'CHAPRA',
// 		state_id: 4,
// 		aliases: 'CHHAPRA',
// 	},
// 	{
// 		label: 'Chennai (Tamil Nadu)',
// 		value: 100,
// 		city_name: 'CHENNAI',
// 		state_id: 24,
// 		aliases: 'Madras',
// 	},
// 	{
// 		label: 'Coimbatore (Tamil Nadu)',
// 		value: 108,
// 		city_name: 'COIMBATORE',
// 		state_id: 24,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Cuttack (Orissa)',
// 		value: 113,
// 		city_name: 'CUTTACK',
// 		state_id: 20,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Dehradun (Uttarakhand)',
// 		value: 126,
// 		city_name: 'DEHRADUN',
// 		state_id: 27,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Delhi (Delhi)',
// 		value: 129,
// 		city_name: 'DELHI',
// 		state_id: 34,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Dhubri (Assam)',
// 		value: 623,
// 		city_name: 'DHUBRI',
// 		state_id: 3,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Dibrugarh (Assam)',
// 		value: 257,
// 		city_name: 'DIBRUGARH',
// 		state_id: 3,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Dombivli (Maharashtra)',
// 		value: 141,
// 		city_name: 'DOMBIVLI',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Dumdum (West Bengal)',
// 		value: 143,
// 		city_name: 'DUMDUM',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Durg (Chhattisgarh)',
// 		value: 144,
// 		city_name: 'DURG',
// 		state_id: 5,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Durgapur Mh (Maharashtra)',
// 		value: 1214,
// 		city_name: 'DURGAPUR MH',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Duttapukur (West Bengal)',
// 		value: 516,
// 		city_name: 'DUTTAPUKUR',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Faridabad (Haryana)',
// 		value: 153,
// 		city_name: 'FARIDABAD',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Gaya (Bihar)',
// 		value: 167,
// 		city_name: 'GAYA',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ghaziabad (Uttar Pradesh)',
// 		value: 168,
// 		city_name: 'GHAZIABAD',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Gorakhpur (Uttar Pradesh)',
// 		value: 173,
// 		city_name: 'GORAKHPUR',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Greater Noida (Uttar Pradesh)',
// 		value: 160,
// 		city_name: 'GREATER NOIDA',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Gurugram (Haryana)',
// 		value: 179,
// 		city_name: 'GURUGRAM',
// 		state_id: 8,
// 		aliases: 'Gurgaon',
// 	},
// 	{
// 		label: 'Guwahati (Assam)',
// 		value: 180,
// 		city_name: 'GUWAHATI',
// 		state_id: 3,
// 		aliases: 'Gauhati',
// 	},
// 	{
// 		label: 'Gwalior (Madhya Pradesh)',
// 		value: 161,
// 		city_name: 'GWALIOR',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Habra (West Bengal)',
// 		value: 1095,
// 		city_name: 'HABRA',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hajipur (Bihar)',
// 		value: 605,
// 		city_name: 'HAJIPUR',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Haldwani (Uttarakhand)',
// 		value: 186,
// 		city_name: 'HALDWANI',
// 		state_id: 27,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hazaribag (Jharkhand)',
// 		value: 861,
// 		city_name: 'HAZARIBAG',
// 		state_id: 11,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hisar (Haryana)',
// 		value: 199,
// 		city_name: 'HISAR',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hooghly (West Bengal)',
// 		value: 1216,
// 		city_name: 'HOOGHLY',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hoshangabad (Madhya Pradesh)',
// 		value: 754,
// 		city_name: 'HOSHANGABAD',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Howrah (West Bengal)',
// 		value: 190,
// 		city_name: 'HOWRAH',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Hyderabad (Telangana)',
// 		value: 201,
// 		city_name: 'HYDERABAD',
// 		state_id: 36,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Indore (Madhya Pradesh)',
// 		value: 202,
// 		city_name: 'INDORE',
// 		state_id: 14,
// 		aliases: 'Indhur',
// 	},
// 	{
// 		label: 'Jabalpur (Madhya Pradesh)',
// 		value: 204,
// 		city_name: 'JABALPUR',
// 		state_id: 14,
// 		aliases: 'Jubbulpore',
// 	},
// 	{
// 		label: 'Jaipur (Rajasthan)',
// 		value: 205,
// 		city_name: 'JAIPUR',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jajpur (Orissa)',
// 		value: 789,
// 		city_name: 'JAJPUR',
// 		state_id: 20,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jalandhar (Punjab)',
// 		value: 208,
// 		city_name: 'JALANDHAR',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jalgaon (Maharashtra)',
// 		value: 209,
// 		city_name: 'JALGAON',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jalpaiguri (West Bengal)',
// 		value: 525,
// 		city_name: 'JALPAIGURI',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jamshedpur (Jharkhand)',
// 		value: 213,
// 		city_name: 'JAMSHEDPUR',
// 		state_id: 11,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jhansi (Uttar Pradesh)',
// 		value: 217,
// 		city_name: 'JHANSI',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jodhpur (Rajasthan)',
// 		value: 219,
// 		city_name: 'JODHPUR',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Jorhat (Assam)',
// 		value: 220,
// 		city_name: 'JORHAT',
// 		state_id: 3,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Kalyani (West Bengal)',
// 		value: 227,
// 		city_name: 'KALYANI',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Kanpur (Uttar Pradesh)',
// 		value: 233,
// 		city_name: 'KANPUR',
// 		state_id: 26,
// 		aliases: 'Cawnpore',
// 	},
// 	{
// 		label: 'Karnal (Haryana)',
// 		value: 239,
// 		city_name: 'KARNAL',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Khardah (West Bengal)',
// 		value: 1091,
// 		city_name: 'KHARDAH',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Kolhapur (Maharashtra)',
// 		value: 253,
// 		city_name: 'KOLHAPUR',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Kolkata (West Bengal)',
// 		value: 256,
// 		city_name: 'KOLKATA',
// 		state_id: 28,
// 		aliases: 'Calcutta',
// 	},
// 	{
// 		label: 'Kota (Rajasthan)',
// 		value: 260,
// 		city_name: 'KOTA',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Lucknow (Uttar Pradesh)',
// 		value: 278,
// 		city_name: 'LUCKNOW',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ludhiana (Punjab)',
// 		value: 279,
// 		city_name: 'LUDHIANA',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Madhyamgram (West Bengal)',
// 		value: 1101,
// 		city_name: 'MADHYAMGRAM',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Mangalore (Karnataka)',
// 		value: 292,
// 		city_name: 'MANGALORE',
// 		state_id: 12,
// 		aliases: 'Mangaluru',
// 	},
// 	{
// 		label: 'Mankundu (West Bengal)',
// 		value: 1096,
// 		city_name: 'MANKUNDU',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Mathura (Uttar Pradesh)',
// 		value: 295,
// 		city_name: 'MATHURA',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Meerut (Uttar Pradesh)',
// 		value: 297,
// 		city_name: 'MEERUT',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Midnapur (West Bengal)',
// 		value: 951,
// 		city_name: 'MIDNAPUR',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Mohali (Punjab)',
// 		value: 299,
// 		city_name: 'MOHALI',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Moradabad (Uttar Pradesh)',
// 		value: 300,
// 		city_name: 'MORADABAD',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Motihari (Bihar)',
// 		value: 301,
// 		city_name: 'MOTIHARI',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Mumbai (Maharashtra)',
// 		value: 304,
// 		city_name: 'MUMBAI',
// 		state_id: 15,
// 		aliases: 'Bombay',
// 	},
// 	{
// 		label: 'Muzaffarnagar (Uttar Pradesh)',
// 		value: 308,
// 		city_name: 'MUZAFFARNAGAR',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Muzaffarpur (Bihar)',
// 		value: 309,
// 		city_name: 'MUZAFFARPUR',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Mysore (Karnataka)',
// 		value: 310,
// 		city_name: 'MYSORE',
// 		state_id: 12,
// 		aliases: 'Mysuru',
// 	},
// 	{
// 		label: 'Nagpur (Maharashtra)',
// 		value: 317,
// 		city_name: 'NAGPUR',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Naihati (West Bengal)',
// 		value: 1092,
// 		city_name: 'NAIHATI',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Narendrapur (West Bengal)',
// 		value: 1164,
// 		city_name: 'NARENDRAPUR',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Nashik (Maharashtra)',
// 		value: 326,
// 		city_name: 'NASHIK',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Navi Mumbai (Maharashtra)',
// 		value: 327,
// 		city_name: 'NAVI MUMBAI',
// 		state_id: 15,
// 		aliases: 'VASHI',
// 	},
// 	{
// 		label: 'New Delhi (Delhi)',
// 		value: 332,
// 		city_name: 'NEW DELHI',
// 		state_id: 34,
// 		aliases: 'Delhi',
// 	},
// 	{
// 		label: 'Noida (Uttar Pradesh)',
// 		value: 335,
// 		city_name: 'NOIDA',
// 		state_id: 26,
// 		aliases: 'Gautam Budh Nagar',
// 	},
// 	{
// 		label: 'Panchkula (Haryana)',
// 		value: 345,
// 		city_name: 'PANCHKULA',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Panipat (Haryana)',
// 		value: 347,
// 		city_name: 'PANIPAT',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Panjim (Goa)',
// 		value: 344,
// 		city_name: 'PANJIM',
// 		state_id: 6,
// 		aliases: 'TIVIM',
// 	},
// 	{
// 		label: 'Patiala (Punjab)',
// 		value: 352,
// 		city_name: 'PATIALA',
// 		state_id: 21,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Patna (Bihar)',
// 		value: 353,
// 		city_name: 'PATNA',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Prayagraj (Uttar Pradesh)',
// 		value: 14,
// 		city_name: 'PRAYAGRAJ',
// 		state_id: 26,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Pune (Maharashtra)',
// 		value: 360,
// 		city_name: 'PUNE',
// 		state_id: 15,
// 		aliases: 'Poona',
// 	},
// 	{
// 		label: 'Puri (Orissa)',
// 		value: 361,
// 		city_name: 'PURI',
// 		state_id: 20,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Raipur (Chhattisgarh)',
// 		value: 370,
// 		city_name: 'RAIPUR',
// 		state_id: 5,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Rajkot (Gujarat)',
// 		value: 372,
// 		city_name: 'RAJKOT',
// 		state_id: 7,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ranchi (Jharkhand)',
// 		value: 779,
// 		city_name: 'RANCHI',
// 		state_id: 11,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Rewa (Madhya Pradesh)',
// 		value: 580,
// 		city_name: 'REWA',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Rishikesh (Uttarakhand)',
// 		value: 1318,
// 		city_name: 'RISHIKESH',
// 		state_id: 27,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Rohtak (Haryana)',
// 		value: 717,
// 		city_name: 'ROHTAK',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Roorkee (Uttarakhand)',
// 		value: 526,
// 		city_name: 'ROORKEE',
// 		state_id: 27,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Sangli (Maharashtra)',
// 		value: 387,
// 		city_name: 'SANGLI',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Satara (Maharashtra)',
// 		value: 390,
// 		city_name: 'SATARA',
// 		state_id: 15,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Satna (Madhya Pradesh)',
// 		value: 391,
// 		city_name: 'SATNA',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Secunderabad (Telangana)',
// 		value: 1203,
// 		city_name: 'SECUNDERABAD',
// 		state_id: 36,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Silchar (Assam)',
// 		value: 407,
// 		city_name: 'SILCHAR',
// 		state_id: 3,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Siliguri (West Bengal)',
// 		value: 413,
// 		city_name: 'SILIGURI',
// 		state_id: 28,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Sirsa (Haryana)',
// 		value: 824,
// 		city_name: 'SIRSA',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Siwan (Bihar)',
// 		value: 418,
// 		city_name: 'SIWAN',
// 		state_id: 4,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Sonipat (Haryana)',
// 		value: 419,
// 		city_name: 'SONIPAT',
// 		state_id: 8,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Surat (Gujarat)',
// 		value: 423,
// 		city_name: 'SURAT',
// 		state_id: 7,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Tezpur (Assam)',
// 		value: 626,
// 		city_name: 'TEZPUR',
// 		state_id: 3,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Udaipur (Rajasthan)',
// 		value: 445,
// 		city_name: 'UDAIPUR',
// 		state_id: 22,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Ujjain (Madhya Pradesh)',
// 		value: 450,
// 		city_name: 'UJJAIN',
// 		state_id: 14,
// 		aliases: null,
// 	},
// 	{
// 		label: 'Vadodara (Gujarat)',
// 		value: 454,
// 		city_name: 'VADODARA',
// 		state_id: 7,
// 		aliases: 'Baroda',
// 	},
// 	{
// 		label: 'Varanasi (Uttar Pradesh)',
// 		value: 459,
// 		city_name: 'VARANASI',
// 		state_id: 26,
// 		aliases: 'Benares',
// 	},
// 	{
// 		label: 'Visakhapatnam (Andhra Pradesh)',
// 		value: 467,
// 		city_name: 'VISAKHAPATNAM',
// 		state_id: 1,
// 		aliases: 'Waltair',
// 	},
// 	{
// 		label: 'Zirakpur (Punjab)',
// 		value: 1351,
// 		city_name: 'ZIRAKPUR',
// 		state_id: 21,
// 		aliases: null,
// 	},
// ];

export const cityStateList = [
	// {
	// 	label: 'Adimaly',
	// 	value: 1443,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Adoor',
	// 	value: 1465,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Alappuzha',
	// 	value: 1387,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ambalathara',
	// 	value: 1494,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Anchal',
	// 	value: 1412,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ancherichira',
	// 	value: 1479,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Aravanchal',
	// 	value: 1399,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Arpookara, kottayam',
	// 	value: 1426,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ayarkunnam',
	// 	value: 1423,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Aymanam, kottayam',
	// 	value: 1427,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ayoor',
	// 	value: 1413,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Aluva',
	// 	value: 799,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Balaramapuram',
	// 	value: 1371,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Bharanikkavu',
	// 	value: 1400,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chaithanya eye hospital',
	// 	value: 1508,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chakkarakkal',
	// 	value: 1391,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chalakudy',
	// 	value: 1480,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Changanassery',
	// 	value: 1424,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chavakkad',
	// 	value: 1481,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Cheganoor',
	// 	value: 1466,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chethimattom,  pala',
	// 	value: 1433,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Chinnakada',
	// 	value: 1401,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Changanacherry',
	// 	value: 651,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Edathua',
	// 	value: 1467,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Enathu',
	// 	value: 1414,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Erattupetta',
	// 	value: 1425,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ernakulam',
	// 	value: 148,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ettumanoor',
	// 	value: 695,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Gh trivandrum',
	// 	value: 1495,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Haripad',
	// 	value: 1468,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Iritty',
	// 	value: 1392,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Irinjalakuda',
	// 	value: 203,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kadakkal',
	// 	value: 1415,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kadavoor',
	// 	value: 1402,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kakkanad',
	// 	value: 1388,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kallambalam',
	// 	value: 1363,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kalpetta',
	// 	value: 1385,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kanjirapally',
	// 	value: 1429,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kanyakulangara',
	// 	value: 1365,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karakkonam',
	// 	value: 1453,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karamana',
	// 	value: 1496,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karatte',
	// 	value: 1366,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karukachal',
	// 	value: 1430,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karunagapally',
	// 	value: 1403,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Karungal',
	// 	value: 1454,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kasargod',
	// 	value: 1393,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kattakada',
	// 	value: 1372,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kattanam medical centre',
	// 	value: 1478,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kattapana',
	// 	value: 1445,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kayamkulam',
	// 	value: 1404,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kazhakootam',
	// 	value: 1497,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kilimanoor',
	// 	value: 1367,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kodali',
	// 	value: 1482,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kolenchery',
	// 	value: 1446,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kondotty',
	// 	value: 1380,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Konni',
	// 	value: 1469,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Koorkenchery',
	// 	value: 1483,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Koothattukulam',
	// 	value: 1447,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kottakkal',
	// 	value: 1381,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kottiyam',
	// 	value: 1405,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Koyilandy',
	// 	value: 1382,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kudappanakunnu',
	// 	value: 1498,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kulasekaram',
	// 	value: 1455,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kulathupuzha',
	// 	value: 1422,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kumbanad',
	// 	value: 1470,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kundara',
	// 	value: 1416,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kunnamkulam',
	// 	value: 1493,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kunnathangadi',
	// 	value: 1484,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kunnikode',
	// 	value: 1417,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kuravankonam',
	// 	value: 1499,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kuravilangadu',
	// 	value: 1431,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kangazha',
	// 	value: 231,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kanjirappally',
	// 	value: 654,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kannur',
	// 	value: 232,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kasaragod',
	// 	value: 846,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kochi',
	// 	value: 251,
	// 	aliases: 'Cochin',
	// },
	// {
	// 	label: 'Kollam',
	// 	value: 366,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kothamangalam',
	// 	value: 275,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kottarakara',
	// 	value: 263,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kottayam',
	// 	value: 5006,
	// 	city_name: 'KOTTAYAM',
	// 	aliases: null,
	// 	state_id: 13,
	// },

	{
		label: 'Aluva (Kerala)',
		value: 5002,
		city_name: 'ALUVA',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Calicut (Kerala)',
		value: 5003,
		city_name: 'CALICUT',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Ernakulam (Kerala)',
		value: 5001,
		city_name: 'ERNAKULAM',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Kannur (Kerala)',
		value: 5004,
		city_name: 'KANNUR',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Kollam (Kerala)',
		value: 5005,
		city_name: 'KOLLAM',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Kottayam (Kerala)',
		value: 5006,
		city_name: 'KOTTAYAM',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Muvattupuzha (Kerala)',
		value: 5007,
		city_name: 'MUVATTUPUZHA',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Nagarcoil (Kerala)',
		value: 5008,
		city_name: 'NAGARCOIL',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Pathanamthitta (Kerala)',
		value: 5009,
		city_name: 'PATHANAMTHITTA',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Test (Madhya Pradesh)',
		value: 5012,
		city_name: 'TEST',
		state_id: 14,
		aliases: null,
	},
	{
		label: 'Thrissur (Kerala)',
		value: 5010,
		city_name: 'THRISSUR',
		state_id: 13,
		aliases: null,
	},
	{
		label: 'Trivandrum (Kerala)',
		value: 5011,
		city_name: 'TRIVANDRUM',
		state_id: 13,
		aliases: null,
	},

	// {
	// 	label: 'Kozhencherry',
	// 	value: 267,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Kozhikode',
	// 	value: 268,
	// 	aliases: 'Calicut',
	// },
	// {
	// 	label: 'Mala',
	// 	value: 1356,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Malayinkeezhu',
	// 	value: 1373,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Manarcadu, kottayam',
	// 	value: 1436,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mangalapuram',
	// 	value: 1500,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Manjapra',
	// 	value: 1357,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mannar',
	// 	value: 1471,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Marthandam',
	// 	value: 1456,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mattannur',
	// 	value: 1394,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mavelikkara',
	// 	value: 1472,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Melpuram',
	// 	value: 1457,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Moolamattom',
	// 	value: 1448,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mukkom',
	// 	value: 1383,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mundakayam',
	// 	value: 1432,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Muvattupuzha',
	// 	value: 1449,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Mundkayam',
	// 	value: 653,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nagercoil',
	// 	value: 1458,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nedumangad',
	// 	value: 1368,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nedumkandam',
	// 	value: 1450,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Neyattinkara',
	// 	value: 1374,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Neyyatinkara ',
	// 	value: 1377,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nilamel',
	// 	value: 1418,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nooranad',
	// 	value: 1473,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Nurani',
	// 	value: 1485,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Oachira',
	// 	value: 1406,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Oorambu',
	// 	value: 1459,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Oyoor',
	// 	value: 1419,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pala town',
	// 	value: 1434,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Palakkad',
	// 	value: 1486,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pambady',
	// 	value: 1435,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Panachamoodu',
	// 	value: 1378,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pandalam',
	// 	value: 1474,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Parassala',
	// 	value: 1460,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Paravoor',
	// 	value: 1407,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Parippally',
	// 	value: 1408,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pathanamthitta',
	// 	value: 1475,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pathanapuram',
	// 	value: 1476,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pattambi',
	// 	value: 1487,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pattazhy',
	// 	value: 1421,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pattikkad',
	// 	value: 1488,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pattimattom',
	// 	value: 1451,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Payyanoor',
	// 	value: 1395,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Perinthalmanna',
	// 	value: 867,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Peroorkada hospital',
	// 	value: 1501,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Perumbavoor',
	// 	value: 1360,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Peyad',
	// 	value: 1375,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ponnani',
	// 	value: 1489,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Poojappura',
	// 	value: 1502,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pothencodu',
	// 	value: 1369,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Promise endocrine clinic',
	// 	value: 1411,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pukattupady',
	// 	value: 1361,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Puthiyatheru',
	// 	value: 1396,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Puthoor',
	// 	value: 1420,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Puthukadai',
	// 	value: 1464,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Pala',
	// 	value: 652,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Palakad',
	// 	value: 798,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Palghat',
	// 	value: 363,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Punalur',
	// 	value: 364,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ramankulangara',
	// 	value: 1409,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ramapuram',
	// 	value: 1437,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ranni',
	// 	value: 1477,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Samkranthy, kottayam',
	// 	value: 1438,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Sasthamangalam',
	// 	value: 1503,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Sasthamcotta',
	// 	value: 1410,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thalassery',
	// 	value: 1397,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thalikulam',
	// 	value: 1490,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thaliparamba',
	// 	value: 1398,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thamarassery',
	// 	value: 1384,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thampuranmukku',
	// 	value: 1504,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thingal nagar',
	// 	value: 1463,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thirumala, alappuzha',
	// 	value: 1442,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thodupuzha',
	// 	value: 1452,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thodupuzha(karikode)',
	// 	value: 1444,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thottakad',
	// 	value: 1439,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thuckalay',
	// 	value: 1461,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thuravoor',
	// 	value: 1389,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thycaud',
	// 	value: 1505,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Tiruchendur',
	// 	value: 1462,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Trichur',
	// 	value: 1491,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Trivandrum',
	// 	value: 1364,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thiruvalla',
	// 	value: 650,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thiruvananthapuram',
	// 	value: 444,
	// 	aliases: 'Trivandrum',
	// },
	// {
	// 	label: 'Thrikkannamangal',
	// 	value: 434,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Thrissur',
	// 	value: 435,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Ulloor',
	// 	value: 1509,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Uzhavoor',
	// 	value: 1440,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vadakara',
	// 	value: 1386,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vadavathoor,  kottayam',
	// 	value: 1441,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vaduthala',
	// 	value: 1390,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Varkala',
	// 	value: 1370,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vattiyoorkavu',
	// 	value: 1506,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vizhinjam',
	// 	value: 1376,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Vikom',
	// 	value: 655,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Wadakkanchery',
	// 	value: 1492,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Westfort',
	// 	value: 1507,
	// 	aliases: null,
	// },
	// {
	// 	label: 'Wayanad',
	// 	value: 474,
	// 	aliases: null,
	// },
];
