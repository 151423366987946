import {
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  CLEAR_COMPARE,
  ADD_COMPARE_PACKAGE,
  REMOVE_COMPARE_PACKAGE,
} from "../action-types/action.types";

const initialStates = {
  compare_list: { // pack ids which can be used for compare and retain position of package
    package_id_1: 0,
    package_id_2: 0
  },
  selected_packs:{
    package_1: {},
    package_2: {},
  },
  package_1: [],
  package_2: [],
};

const productCompare = (state = initialStates, action) => {
  
  switch (action.type) {
    
    case ADD_TO_COMPARE:
      let compare_list = state.compare_list;
      let selected_packs = state.selected_packs;
      if(!compare_list.package_id_1){
        compare_list.package_id_1 = action.payload.pack.ID;
        selected_packs.package_1 = action.payload.pack;

      }else if(!compare_list.package_id_2){
        compare_list.package_id_2 = action.payload.pack.ID;
        selected_packs.package_2 = action.payload.pack;

      }
      return {
        ...state,
        compare_list,
        selected_packs,
      };

    case REMOVE_FROM_COMPARE:
      let newState = {...state};
      if(action.payload.pack === 1){
        newState.compare_list.package_id_1 = 0;
        newState.package_1 = [];
        newState.selected_packs.package_1 = {};
        
      }else if(action.payload.pack === 2){
        newState.compare_list.package_id_2 = 0;
        newState.package_2 = [];
        newState.selected_packs.package_2 = {};
      
      }
      return newState;

    case CLEAR_COMPARE:
      return {
        compare_list: {
          package_id_1: 0,
          package_id_2: 0
        },
        selected_packs:{
          package_1: {},
          package_2: {},
        },
        package_1: [],
        package_2: [],
      };
      
      case ADD_COMPARE_PACKAGE:
          return {
            ...state,
            package_1 : action.payload.package_1,
            package_2 : action.payload.package_2,
          }
        
        case REMOVE_COMPARE_PACKAGE:
          if(action.payload.pack === 1){
            return {
              ...state,
              package_1 : [],
            }
          }else if(action.payload.pack === 2){
            return {
              ...state,
              package_2 : [],
          }
        }
        
    default:
      return state;
  }
};

export default productCompare;
