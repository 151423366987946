import {
    SAVE_HOME_PACKAGES_LIST,
    SAVE_HOME_TESTS_LIST,
    SAVE_HOME_COVID_TESTS_PACKAGES_LIST,
    SET_FILTER_OPTION,
    SAVE_SEARCH_FLAG,
    RESET_FEED
} from '../action-types/action.types';

const initialStates={
    home_package_list:[],
    home_tests_list:[],
    covid_tests_packages_list:[],
    package_filter_option:'',
    test_filter_option:'',
    search_flag:'',
    booking_data:{},
    loader_count: 0,
}

const feed = (state= initialStates, action)=>{
    switch (action.type) {
        case SAVE_HOME_PACKAGES_LIST:
            return {
                ...state,
                home_package_list: action.payload, 
                loader_count: state.loader_count > 0 ? state.loader_count - 1 : 0,
            }    
        case SAVE_HOME_TESTS_LIST:
            return {
                ...state,
                home_tests_list: action.payload,
                loader_count: state.loader_count > 0 ? state.loader_count - 1 : 0,
            }  
        case SAVE_HOME_COVID_TESTS_PACKAGES_LIST:
            return {
                ...state,
                covid_tests_packages_list: action.payload,
                loader_count: state.loader_count > 0 ? state.loader_count - 1 : 0,
            }    
        case SET_FILTER_OPTION:
            return {
                ...state,
                package_filter_option: action.payload.package_filter_option,
                test_filter_option: action.payload.test_filter_option
            }
        case SAVE_SEARCH_FLAG:
            return {
                ...state,
                search_flag: action.payload
            }  
        case "INCREMENT_LOADER": 
            return {
                ...state,
                loader_count: state.loader_count + action.payload
            }
        case "DECREMENT_LOADER": 
            return {
                ...state,
                loader_count: state.loader_count > 0 ? state.loader_count - action.payload : 0,
            }
        case RESET_FEED: 
            return {
                ...state,
                home_package_list:[],
                home_tests_list:[],
                package_filter_option:'',
                test_filter_option:'',
                search_flag:'',
                booking_data:{},
                loader_count: 0,
            }
        case "SET_BOOKING_FLAG":
            let product_id = localStorage.getItem('product_id');
            localStorage.setItem('product_id',action.payload);
            return {
                ...state,
                product_id: action.payload || product_id,
            }   
        case "GOT_TEST_PACKAGE_DETAILS":
            return {
                ...state,
                booking_data : action.payload
            }
        default: return state;
    }
       
}

export default feed;
