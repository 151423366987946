import { 
    SET_CAMPAIGN_DATA, 
    RESET_CAMPAIGN_DATA, 
	SHOW_REQUEST_CALLBACK,
	HIDE_REQUEST_CALLBACK,
	SHOW_IMAGE_MODAL,
	HIDE_IMAGE_MODAL,
	FACEBOOK_PIXEL_LEAD_ENABLE,
	FACEBOOK_PIXEL_LEAD_DISABLE,
} from '../action-types/action.types';

const initialStates = {
	campaign_url: '',
	can_show_request_callback: false,
	image_modal_data: {},
	can_show_image_modal: false,
	facebook_pixel_lead_enable:false,
};

const analytics = (state = initialStates, action) => {
	switch (action.type) {
		case SET_CAMPAIGN_DATA: {
			return {
                ...state,
				campaign_url: action.payload.url
			};
		}
		case RESET_CAMPAIGN_DATA: {
			return {
                ...state,
				campaign_url: '',
			};
		}
		case SHOW_REQUEST_CALLBACK: {
			sessionStorage.setItem('request_call_backs', '1')
			return {
                ...state,
				can_show_request_callback: true,
			};
		}
		case HIDE_REQUEST_CALLBACK: {
			return {
                ...state,
				can_show_request_callback: false,
			};
		}
		case SHOW_IMAGE_MODAL: {
			sessionStorage.setItem('image_modal', '1')
			return {
                ...state,
				image_modal_data: action.payload,
				can_show_image_modal: true,
			};
		}
		case HIDE_IMAGE_MODAL: {
			return {
                ...state,
				image_modal_data: {},
				can_show_image_modal: false,
			};
		}
		case FACEBOOK_PIXEL_LEAD_ENABLE:{
			return {
				...state,
				facebook_pixel_lead_enable:true,
			}
		}
		case FACEBOOK_PIXEL_LEAD_DISABLE:{
			return {
				...state,
				facebook_pixel_lead_enable:false,
			}
		}
		default:
			return state;
	}
};

export default analytics;
