import { combineReducers } from 'redux';

import auth from './auth.reducer';
import location from './location.reducer';
import loader from './loader.reducer';
import feed from './feed.reducer';
import cart from './cart.reducer';
import wishlist from './wishlist.reducer';
import notification from './notification.reducer';
import familyMember from './registerFamily.reducer'
import productCompare from './productCompare.reducer';
import route from './route.reducer';
import analytics from './analytics.reducer';


const reducers = combineReducers({
    auth,
    location,
    loader,
    feed,
    cart,
    wishlist,
    notification,
    familyMember,
    productCompare,
    route,
    analytics,
});

export default reducers;