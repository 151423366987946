import {
    SET_CITY_LIST,
    SET_LOCATION,
    DESTROY_LOCATION
} from '../action-types/action.types';

const initialStates={
    city_list:[],
    previous_location:{},
    current_location:{}
}

const location = (state= initialStates, action)=>{
    switch (action.type) {
        case SET_CITY_LIST:
            return {
                ...state,
                city_list: action.payload
            } 
        case SET_LOCATION:
            return {
                ...state,
                previous_location: state.current_location,
                current_location: action.payload
            }    
        case DESTROY_LOCATION: return{
            state:undefined
        }
        default: return state;
    }
       
}

export default location;
