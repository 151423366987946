import {
	ADD_TO_CART,
	REMOVE_FROM_CART,
	DESTROY_CART,
	CART_PRODUCT_CITY_CHECKING,
	UPDATE_CART_PRODUCTS,
	SET_CART_USERS_LIST,
	SET_CART_SELECTED_USER,
	RESET_CART_SELECTED_USER,
	SET_CART_SOMEONE_ELSE_LIST,
	CLEAR_CART_USER_ALL,
	SET_CART_USER_ADDRESS,
	CART_APPLY_PROMO_CODE,
	CART_VERIFY_SRL_POINTS,
	CART_RESET_PROMO_CODE,
	CART_SET_SRL_POINTS,
	CART_RESET_SRL_POINTS,
	CART_EXPIRE_SRL_POINTS,
	SET_CART_PP_TEST_OPTION,
	SET_CART_HARD_COPY_OPTION,
	SET_CART_SELECTED_LAB_CENTER,
	SET_COMBINED_CART_PRODUCTS,
	SET_CART_BILL_DETAILS,
	SET_SAMPLE_COLLECTION_TYPE_OPTION,
} from '../action-types/action.types';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { toast } from 'react-toastify';


const initialStates = {
	cart_data: [],
	users_list: [],
	someone_else_list: [],
	selected_users: [],
	user_address: {},
	combined_cart_products: {},
	bill_details: {},
	applied_promo_code: {},
	srl_points_redeemed: {},
	pp_test: {},
	is_hard_copy_selected: false,
	selected_lab_center: {},
	sample_collection_type: 'home_visit',
};

const cart = (state = initialStates, action) => {
	switch (action.type) {
		case ADD_TO_CART:
			return {
				...state,
				combined_cart_products: {},
				bill_details: {},
				applied_promo_code: {},
				selected_lab_center: {},
				cart_data: action.payload,
			};
		case REMOVE_FROM_CART:
			let cart_data = [...state.cart_data];
			// GA4 REMOVE FROM CART EVENT
			let item_to_remove = cart_data.filter((item) => item.ID == action.payload);	
			const senitizedData = [
				{
					item_id: item_to_remove[0].ID,
					item_name: item_to_remove[0].PRDCT_ALIASES,
					item_category: item_to_remove[0].CATEGORY,
					item_brand: 'DDRC',
					price: item_to_remove[0].PRICE,
					quantity:1
				},
			];
			window.gtag('event', 'remove_from_cart', { currency: 'INR', value: senitizedData[0].price, items: senitizedData });
			//handleGA4Event('remove_from_cart', item_to_remove);
			cart_data = cart_data.filter((item) => item.ID != action.payload);
			return {
				...state,
				combined_cart_products: {},
				bill_details: {},
				applied_promo_code: {},
				selected_lab_center: {},
				cart_data,
			};
		case SET_CART_USERS_LIST:
			return {
				...state,
				users_list: action.payload.users_list,
			};
		case SET_CART_SELECTED_USER:
			const selected_users = state.selected_users;
			let new_user_list = [];

			if (selected_users && selected_users.length > 0) {
				const user = find(selected_users, { ptnt_cart: action.payload.selected_user.ptnt_cart });
				if (user && !isEmpty(user)) {
					if (selected_users.length > 1) {
						new_user_list = filter(selected_users, (selected_user) => selected_user.ptnt_cart !== user.ptnt_cart);
					} else {
						new_user_list = selected_users;
					}
				} else {
					if (selected_users.length > 2) {
						new_user_list = selected_users;
						toast.warning('At most 3 users allowed');
					} else {
						if (action.payload.isLoggedUser) {
							new_user_list = [action.payload.selected_user, ...state.selected_users]; // Add the logged user to top
						} else {
							new_user_list = [...state.selected_users, action.payload.selected_user]; // Add the non logged user at bottom
						}
					}
				}
			} else {
				new_user_list.push(action.payload.selected_user);
			}
			// ONLY one user 
			new_user_list = [action.payload.selected_user];
			return {
				...state,
				selected_users: new_user_list,
			};
		case RESET_CART_SELECTED_USER:
			let new_reset_user_list = [];
			if (action.payload.selected_user && !isEmpty(action.payload.selected_user)) {
				new_reset_user_list.push(action.payload.selected_user);
			}
			return {
				...state,
				selected_users: new_reset_user_list,
			};
		case SET_CART_SOMEONE_ELSE_LIST:
			let user_list = [...state.someone_else_list];
			user_list.push(action.payload.someone_else);
			return {
				...state,
				someone_else_list: [...user_list],
			};
		case SET_CART_USER_ADDRESS:
			return {
				...state,
				user_address: action.payload.user_address,
			};
		case SET_CART_SELECTED_LAB_CENTER:
			return {
				...state,
				selected_lab_center: action.payload.lab_center, //Lab or collection center
			};
		case SET_COMBINED_CART_PRODUCTS:
			return {
				...state,
				combined_cart_products: action.payload, //Lab or collection center
			};
		case SET_CART_BILL_DETAILS:
			return {
				...state,
				bill_details: action.payload, //Lab or collection center
			};
		case CART_PRODUCT_CITY_CHECKING:
			let products = [];
			if (action.payload.new_cart_data && action.payload.new_cart_data.length > 0) {
				products = action.payload.new_cart_data;
			}
			return {
				...state,
				cart_data: products,
				// applied_promo_code: {},
				// selected_lab_center: {},
			};
		case UPDATE_CART_PRODUCTS:
			let updatedProducts = [];
			if (action.payload.new_cart_data && action.payload.new_cart_data.length > 0) {
				updatedProducts = action.payload.new_cart_data;
			}
			return {
				...state,
				cart_data: updatedProducts,
				// applied_promo_code: {},
				// selected_lab_center: {},
			};
		case CLEAR_CART_USER_ALL:
			return {
				...state,
				users_list: [],
				selected_users: [],
				someone_else_list: [],
				user_address: {},
				bill_details: {},
				applied_promo_code: {},
				srl_points_redeemed: {},
				// pp_test: {},
				is_hard_copy_selected: false,
				selected_lab_center: {},
			};
		case CART_APPLY_PROMO_CODE:
			return {
				...state,
				applied_promo_code: action.payload.appliedPromo,
			};
		case CART_RESET_PROMO_CODE:
			return {
				...state,
				applied_promo_code: {},
			};
		case CART_SET_SRL_POINTS:
			return {
				...state,
				srl_points_redeemed: action.payload,
			};
		case CART_VERIFY_SRL_POINTS:
			const verfied_data = { ...state.srl_points_redeemed, redeem_otp_code: action.payload.redeem_otp_code, redeemed: true };
			return {
				...state,
				srl_points_redeemed: verfied_data,
			};
		case CART_RESET_SRL_POINTS:
			return {
				...state,
				srl_points_redeemed: {},
			};
		case CART_EXPIRE_SRL_POINTS:
			return {
				...state,
				srl_points_redeemed: state.srl_points_redeemed && !isEmpty(state.srl_points_redeemed) ? { ...state.srl_points_redeemed, expired: true } : {},
			};
		case SET_CART_PP_TEST_OPTION:
			return {
				...state,
				pp_test: typeof action.payload === 'object' ? action.payload : {},
			};
		case SET_CART_HARD_COPY_OPTION:
			return {
				...state,
				is_hard_copy_selected: action.payload.isSelected ? true : false,
			};
		case SET_SAMPLE_COLLECTION_TYPE_OPTION:
			return {
				...state,
				sample_collection_type:  action.payload,
			};
		case DESTROY_CART:
			return {
				cart_data: [],
				users_list: [],
				someone_else_list: [],
				selected_users: [],
				user_address: {},
				bill_details: {},
				applied_promo_code: {},
				srl_points_redeemed: {},
				pp_test: {},
				is_hard_copy_selected: false,
				selected_lab_center: {},
				sample_collection_type: 'home_visit',
			};
		default:
			return state;
	}
};

export default cart;
