import { Route, Switch, useLocation } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import isEmpty from 'lodash/isEmpty';
import ReactPixel from 'react-facebook-pixel';
import API from '../src/shared/API-EXPLICT';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import './assets/css/styles.css';
import './assets/css/inner-styles.css';
import './assets/css/inner-styles-2.css';
import './assets/css/font-awesome.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getAllCities } from './action/location';
import SrlLogoLoader from './components/loaders/SrlLogoLoader';
import ErrorFallback from './Error/ErrorBoundary/ErrorFallback';
import errorHandler from './Error/ErrorBoundary/errorHandler';
import { logout, setUserSrlPoints } from './action/auth';
import { withSuspense } from './hoc/withSuspense';
import { setCampaignData, showRequestCallback, showImageModal } from './action/analytics.action';
import { getRequestCallbackCityList } from './shared/non-redux-api';
import { setLoginSessionTimer } from './shared/Common';
import { getRemainingTokenTime } from './shared/Helper';

import crypto from 'crypto-browserify';
import { Buffer } from 'buffer/';
import { Transform } from 'stream-browserify';
import Swal from 'sweetalert2';
global.Buffer = Buffer;
global.Transform = Transform;
window.Transform = Transform;
const Routes = withSuspense(
	lazy(() => import(/* webpackChunkName: "routes" */ './routes/Routes')),
	<SrlLogoLoader />
);

function App() {
	const browserPath = useLocation();
	const dispatch = useDispatch();
	const isloggedin = useSelector((state) => state.auth.isloggedin);
	const session_timer = useSelector((state) => state.route.timer);
	const auth_token = useSelector((state) => state.auth.auth_token);
	const location = useSelector((state) => state.location.current_location);
	const [mode, setMode] = useState(true);

	const checkUserAuth = async (dispatch) => {
		await API.get(`/check/auth`).then((res) => {
			if (res.data.message === 'Your Password has been changed') {
				// localStorage.clearItem('token');
				Swal.fire({
					title: 'Alert',
					text: res.data.message || 'Password Changed',
					icon: 'success',
					confirmButtonColor: '#3085d6',
				});
				dispatch(logout());
			}
		});
	};

	useEffect(() => {
		if (isloggedin) {
			checkUserAuth(dispatch);
		}
	}, [isloggedin, browserPath, dispatch]);

	const disableLogs = () => {
		// Disable logs in production
		if (process.env.NODE_ENV !== 'development') {
			let noOp = function () {}; // no-op function
			if (!window.console) {
				console = {
					log: noOp,
					warn: noOp,
					error: noOp,
				};
			} else {
				console = {
					...console,
					log: noOp,
					warn: noOp,
					error: noOp,
				};
			}
		}
	};

	const checkUrlForSource = () => {
		const current_url = window.location.href;
		if (current_url.search('utm_source') > 0) {
			const source = current_url.substr(current_url.lastIndexOf('?') + 1);
			dispatch(setCampaignData(source));
		}
	};

	const checkSessionTimer = () => {
		const remainingTime = getRemainingTokenTime(auth_token);
		setLoginSessionTimer(session_timer, dispatch, remainingTime);
	};

	const checkCityForRequestCallback = () => {
		const hasDisabledCallbackModal = sessionStorage.getItem('request_call_backs') === '1';
		const hasDisabledImageModal = sessionStorage.getItem('image_modal') === '1';
		// const websiteUrl = process.env.REACT_APP_URL;
		// const localHostUrl = `http://localhost:${process.env.REACT_APP_PORT}/`
		// const isHomePage = window.location.pathname === '/' && (window.location.href === websiteUrl || window.location.href === localHostUrl);
		const isHomePage = window.location.pathname === '/';
		// console.log('....', window.location, isHomePage);

		if ((!hasDisabledCallbackModal || !hasDisabledImageModal) && isHomePage) {
			if (location && !isEmpty(location)) {
				getRequestCallbackCityList()
					.then((res) => {
						if (res.data && res.data.length > 0) {
							const popupCity = res.data.find((city) => city.city_id == location.value);
							if (popupCity && !isEmpty(popupCity) && !location.is_default) {
								if (popupCity.type && popupCity.type === 1 && !hasDisabledCallbackModal) {
									dispatch(showRequestCallback());
								} else if (popupCity.type && popupCity.type === 2 && !hasDisabledImageModal) {
									dispatch(showImageModal(popupCity));
								}
							} else if (popupCity == undefined && !hasDisabledCallbackModal) {
								dispatch(showRequestCallback());
							}
						}
					})
					.catch((error) => {
						console.log('Error->', error);
					});
			}
		}
	};

	useEffect(() => {
		const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
		const options = {
			autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
			debug: false, // enable logs
		};
		ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, {}, options);
		ReactPixel.pageView();
	}, []);

	useEffect(() => {
		if (isloggedin) {
			checkSessionTimer();
			dispatch(setUserSrlPoints());
		}
	}, [isloggedin]);

	useEffect(() => {
		dispatch(getAllCities());
		// if (isloggedin) {
		//     dispatch(setUserSrlPoints());
		// }

		// disableLogs();

		checkUrlForSource();
	}, []);

	useEffect(() => {
		checkCityForRequestCallback();
	}, [location]);

	window.addEventListener('online', () => setMode(true));
	window.addEventListener('offline', () => setMode(false));

	useEffect(() => {
		//GET_KEY();
	}, []);

	const GET_KEY = () => {
		// Creating Client

		const client = crypto.createDiffieHellman(256);
		const clientPublicKey = client.generateKeys().toString('base64');

		// Get Server Public key
		API.get(`/profile`, {
			clientPublicKey: clientPublicKey,
		}).then((response) => {
			const serverPublicKey = Buffer.from(response.data, 'base64');
			const sharedSecret = client.computeSecret(serverPublicKey, 'base64');

			API.post(`/homeinfo`, {
				sharedSecret: sharedSecret,
			}).then((response) => {
				var iv = Buffer.from(response.data.iv, 'hex');

				const decipher = crypto.createDecipheriv('aes-256-cbc', sharedSecret, iv);

				let decrypted = decipher.update(response.data.encrypted, 'hex', 'utf8');
				decrypted += decipher.final('utf8');

				localStorage.setItem('decrypted_KEY', JSON.stringify(decrypted.replaceAll('"', '')));
			});
		});
	};

	return (
		<>
			{!navigator.onLine ? <div className="disabled-pwa"></div> : <></>}
			<ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
				<Switch>
					<Route path="/" component={Routes} />
				</Switch>
			</ErrorBoundary>
			<ToastContainer />
		</>
	);
}

export default App;
