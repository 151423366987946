import moment from "moment";
import jwt_decode from 'jwt-decode';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import isEmpty from 'lodash/isEmpty';
// import { GiLeatherVest } from "react-icons/gi";

export const htmlDecode = (string) => {
	const Entities = require("html-entities").AllHtmlEntities;
	const entities = new Entities();
	return entities.decode(string);
}

export const camelCase = (str) => {
	if (str) {
		return str.split(' ').map(function (word, index) {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}).join(' ');
	}
	return '';
}

export const scrollTo = (id) => { // For full window scroll bar
	let scrollDIV = document.getElementById(id);
	let scrollPosition = scrollDIV && scrollDIV.offsetTop;
	if (scrollPosition) {
		window.scrollTo({
			top: (scrollPosition - 150),
			behavior: 'smooth'
		});
	}
}

export const scrollToTopOfDiv = (id) => { //For scroll bars
	let scrollDIV = document.getElementById(id);
	if (scrollDIV) {
		scrollDIV.scrollTop = 0;
	}
}

export const kebabCase = (fieldName, sperator = " ") => { // eg. first name --> first-name
	if (fieldName) {
		return fieldName.split(sperator).join("-");
	}
	return fieldName;
};

export const setFieldName = (fieldName, sperator = "_") => { // eg. first_name --> First Name
	if (fieldName) {
		return fieldName.split(sperator).map((word) => {
			return `${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()} `;
		}).join("").trim();
	}
	return fieldName;
};

export const calculateAge = (dob) => { // input must be in timestamp
	if (dob && dob != "") {
		return moment().diff(moment(dob, "x"), "years");
	} else {
		return "-";
	}
}

export const setSearchKeyword = (keyword, sperator = " ") => {
	if (keyword) {
		return keyword.split(sperator).join("-").toLowerCase();
	}
	return "";
};

export const getSearchKeyword = (keyword, sperator = "-") => {
	if (keyword) {
		return keyword.split(sperator).join(" ").toUpperCase();
	}
	return keyword;
};

export const getDeviceId = async () => {
	const fpPromise = FingerprintJS.load();
	const fp = await fpPromise;
	const result = await fp.get()
	const visitorId = result.visitorId;
	return visitorId;
}

export const stringToSlug = (str, seperator = "-") => {
	if (str) {
		str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase(); // replace with space
		str = str.replace(/^\s+|\s+$/gm, ''); //remove whitespace
		str = str.replace(/\s+/g, seperator);	// replace with seperator
		return str;
	}
	return '-';
}

export const shortenString = (str, len = 50) => {
	let newStr = str.substr(0, len) + "...";
	return newStr;
}

export const splitLiteralBodyByCommas = (input) => {
	let out = [];
	let iLen = input.length;
	let parens = 0;
	let state = "";
	let buffer = "";

	for (let i = 0; i < iLen; i++) {
		if (input[i] == ',' && !parens && !state) {
			out.push(buffer);
			buffer = "";
		} else {
			buffer += input[i];
		}
		switch (input[i]) {
			case '(':
			case '[':
			case '{':
				if (!state) parens++;
				break;
			case ')':
			case ']':
			case '}':
				if (!state) if (!parens--)
					// throw new SyntaxError("closing paren, but no opening");
					console.log("closing paren, but no opening");
				break;
			case '"':
				if (!state) state = '"';
				else if (state === '"') state = '';
				break;
			case "'":
				if (!state) state = "'";
				else if (state === "'") state = '';
				break;
			case '\\':
				buffer += input[++i];
				break;
		}
	}
	if (state || parens)
		// throw new SyntaxError("unfinished input");
		console.log("unfinished input");
	out.push(buffer);
	return out;
}

export const objToString = (obj) => {
	let str = '';
	for (let p in obj) {
		if (obj.hasOwnProperty(p) && obj[p]) {
			str += setFieldName(p) + ' : ' + obj[p] + '\n';
		}
	}
	return str;
}

export const readFileAsArrayBuffer = (file) => {
	return new Promise((resolve, reject) => {
		try {
			let reader = new FileReader();
			reader.addEventListener('load', function (e) {
				resolve(e.target.result);
			}, false);
			reader.readAsArrayBuffer(file);
		} catch(err) {
			reject(err);
		}
	});
}

export const getDaysArray = (numberOfDays = 30) => {
	const days = [];
	const dateStart = moment();
	const dateEnd = moment().add(numberOfDays, 'days');
	while (dateEnd.diff(dateStart, 'days') >= 0) {
		days.push({
			date	: dateStart.format('D'),
			day		: dateStart.format('ddd'),
			month	: dateStart.format('MMM'),
			full	: dateStart.format('YYYY-MM-DD'),
		});
		dateStart.add(1, 'days');
	}
	return days
}


export const getTimeArray = (start, end, interval, period = "hours", round = false) => {
	let startTime = moment(start, 'HH:mm A');
	let endTime = moment(end, 'HH:mm A');
	// const timeOffSet = 2; 
	// const timeOffSetUnit = "hours";

	if (round) {
		if (startTime.minute()) {
			startTime = startTime.add(60 - startTime.minute(), "minutes");
		}
		if (endTime.minute()) {
			endTime = endTime.subtract(60 - endTime.minute(), "minutes");
		}
	}

	let time = [];
	while (startTime <= endTime) {
		time.push(new moment(startTime).format('hh:mm A'));
		startTime.add(interval, period);
	}
	return time;
}

export const getRemainingTokenTime = (token) => {
	let decodeData = jwt_decode(token);
	if(decodeData && !isEmpty(decodeData)){
		const expiry_time = decodeData.exp;
		const current_time = Math.round(new Date().getTime() / 1000);
		const remaining_time = expiry_time - current_time;
		if(remaining_time > 0){
			return remaining_time * 1000;
		}
		return 0;
	}
}

export const getPhoneNumberFromString = (numbers,seperator = " ") => {
	if(typeof numbers == 'string' && numbers.length > 0){
		return numbers.split(seperator);
	}
	return [];
}