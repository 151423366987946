import Axios from 'axios';
import { SET_CITY_LIST, SET_LOCATION } from '../action-types/action.types';
import API from '../shared/API-EXPLICT';
import { cartResetPromoCode, cartResetSrlPoints } from './cart.action';
import * as ErrorMessages from '../Error/ErrorMessage/errorMessage';
import { cityStateList } from '../shared/CityStateList';

export const getAllCities = (request, onSuccess, onError)=>{
    return dispatch =>{
        API.get(`/feed/get-city-state-list`, request)
        .then(res=>{
            if(res && res.data.status === "1"){
                dispatch({
                    type:SET_CITY_LIST,
                    payload: res.data.data
                })
                onSuccess && onSuccess();
            }else{
                onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
            }
        })
        .catch(error=>{
            if(error && error.data){
                onError && onError(error.data);
            }else{
                onError && onError({message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER});
            }
        })
    }
}

// export const getAllCities = (onSuccess, onError) => {
// 	return (dispatch) => {
// 		if (cityStateList && cityStateList.length > 0) {
// 			dispatch({
// 				type: SET_CITY_LIST,
// 				payload: cityStateList,
// 			});
// 			onSuccess && onSuccess();
// 		} else {
// 			onError && onError({ message: 'City list not available!' });
// 		}
// 	};
// };
// Statically added the state id and changed the API form get to post /get-city-state-list-lead
export const getAllCitiesLead = (onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/feed/city-list`, { state_id: '13' })
			.then((res) => {
				if (res && res.data.status === '1') {
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

// export const getAllStates = (request, onSuccess, onError)=>{
//     return dispatch =>{
//         API.get(`/feed/state-list`, request)
//         .then(res=>{
//             if(res && res.data.status === "1"){
//                 dispatch({
//                     type:SET_STATE_LIST,
//                     payload: res.data.data
//                 })
//                 onSuccess && onSuccess();
//             }else{
//                 onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
//             }
//         })
//         .catch(err=>{
//             console.log("State ERROR:",err)
//         })
//     }
// }

export const getUserIp = (onSuccess, onError) => {
	return (dispatch) => {
		Axios.get(`https://api.ipify.org/?format=json`)
			.then((res) => {
				if (res.status === 200) {
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const setCurrentLocation = (location, onSuccess, onError) => {
	return (dispatch) => {
		if (Object.keys(location).length > 0) {
			dispatch({
				type: SET_LOCATION,
				payload: location,
			});
			dispatch(cartResetSrlPoints());
			dispatch(cartResetPromoCode());
			onSuccess && onSuccess();
		} else {
			onError && onError();
		}
	};
};
