import { useHistory } from "react-router";
import somethingWrongBg from '../../assets/images/something-wrong.svg';

const ErrorFallback = ({error, resetErrorBoundary}) => {

  const history = useHistory();

	return (
		<div>
			<div className="something-wrong-bg"></div>
			<div role="alert" className="something-wrong">
				<img src={somethingWrongBg} alt="" />
				<p>Something went wrong!</p>
				<button 
					className="btn remove-cart-btn mr-2" 
					onClick={()=>{
						resetErrorBoundary(); 
						history.push('/')
					}}
				>
					Home
				</button>
				<button 
					className="btn add-cart-btn" 
					onClick={resetErrorBoundary}
				>
					Reload
				</button>
			</div>
		</div>
	);
}


export default ErrorFallback;