import logoLoad from '../../assets/images/logoJpg.png';
// import logoLoadWebp from '../../assets/images/DDRC_SRL_Logo.png';
import logoLoadWebp from '../../assets/images/ddrc_new_logo.jpg';

import logoLoadGif from '../../assets/images/logoLoad.gif';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const SrlLogoLoader = () => {
	return (
		<div className="logo-loading">
			<div className="text-center">
				<img src={isWebpSupported ? logoLoadWebp : logoLoad} width="300" alt="SRL LOADER" />
				<br />
				<img src={logoLoadGif} alt="SRL LOADER" />
			</div>
		</div>
	);
};

export default SrlLogoLoader;
