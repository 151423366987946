import {
	REGISTRATION_SUCCESS,
	GET_OTP_SUCCESS,
	GET_OTP_FAIL,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAIL,
	REGISTRATION_FAIL,
	LOGIN,
	LOGOUT,
	DESTROY_SESSION,
	RESET_FEED,
	CLEAR_CART_USER_ALL,
	DESTROY_CART,
	DESTROY_ROUTE,
	UPDATE_USER_SRL_POINTS,
	UPDATE_USER_SRL_POINTS_CART,
	DESTROY_WISHLIST,
} from '../action-types/action.types';
import jwt_decode from 'jwt-decode';

import API from '../shared/API-EXPLICT';
import { persistor } from '../store/index';
import { decryptionText, TEMP } from '../shared/cookieStore';
import * as ErrorMessages from '../Error/ErrorMessage/errorMessage';
import { decodeUserLoginToken, handleGoogleTag } from '../shared/Common';
import { GTAG_EVENT_PATIENT_ADDED, GTAG_EVENT_REGISTER_SUCCESS } from '../shared/Constants';

const setupUserLogin = (res, dispatch) => {
	let decodeData = jwt_decode(res.data.token);

	const userData = {
		ptnt_id: decryptionText(decodeData.ptn_id, TEMP.SECRET),
		ptnt_points: decodeData.ptnt_points,
		name: decryptionText(decodeData.full_name, TEMP.SECRET),
		gender: decryptionText(decodeData.gender, TEMP.SECRET),
		dob: decryptionText(decodeData.dob, TEMP.SECRET),
		email: decryptionText(decodeData.email, TEMP.SECRET),
		mobile_no: decryptionText(decodeData.mobile_no, TEMP.SECRET),
		profile_pic: decodeData.profile_pic,
	};
	localStorage.setItem('token', res.data.token);
	localStorage.setItem('refresh_token', res.data.refresh_token);
	dispatch({
		type: LOGIN,
		payload: {
			isloggedin: res.data.token !== null || res.data.token != undefined ? true : false,
			auth_token: res.data.token,
			userData: userData,
			show_popup: res.data.show_popup,
		},
	});
};

export const getRegistrationOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register/get-otp`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: GET_OTP_SUCCESS,
						payload: res.data.message,
					});
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				}
			});
	};
};

export const resendRegistrationOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register/resend-otp`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					// dispatch({
					//     type:GET_OTP_SUCCESS,
					//     payload: res.data.message
					// })
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				}
			});
	};
};

export const verifyRegistrationOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register/verify-otp`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: VERIFY_OTP_SUCCESS,
						payload: res.data.data,
					});
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				}
			});
	};
};

export const createUser = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: REGISTRATION_SUCCESS,
						payload: res.data.responseResult,
					});
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((err) => {
				console.log('createUser ERROR:', err);
			});
	};
};

export const setUserSrlPoints = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/user-points`)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: UPDATE_USER_SRL_POINTS,
						payload: { points_left: request ? String(Number(res.data.points.points_left) + Number(request)) : res.data.points.points_left },
					});
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((err) => {
				console.log('srlpoints ERROR:', err);
			});
	};
};

export const updateUserSrlPointsCart = (points) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_USER_SRL_POINTS_CART,
			payload: { points_left: points },
		});
	};
};

export const loginByUserId = (data, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/login`, data)
			.then((res) => {
				if (res && res.data.status === '1') {
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const register = (data, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register`, data)
			.then((res) => {
				if (res && res.data.status === '1') {
					const decodedUserData = decodeUserLoginToken(res.data.token)
          handleGoogleTag(GTAG_EVENT_PATIENT_ADDED,decodedUserData)
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const getLoginOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/login/get-otp`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					// dispatch({
					//     type:GET_OTP_SUCCESS,
					//     payload: res.data.message
					// })
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const resendLoginOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/register/resend-otp`, request)
			.then((res) => {
				if (res && res.data.status === 1) {
					// dispatch({
					//     type:GET_OTP_SUCCESS,
					//     payload: res.data.message
					// })
					onSuccess && onSuccess();
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const verifyLoginOTP = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/login/verify-otp`, request)
			.then((res) => {
				if (res && res.data.status === '1') {
					if (res.data.token != null) {
						let decodeData = jwt_decode(res.data.token);
						const userData = {
							ptnt_id: decryptionText(decodeData.ptn_id, TEMP.SECRET),
							ptnt_points: decodeData.ptnt_points,
							name: decryptionText(decodeData.full_name, TEMP.SECRET),
							gender: decryptionText(decodeData.gender, TEMP.SECRET),
							dob: decryptionText(decodeData.dob, TEMP.SECRET),
							email: decryptionText(decodeData.email, TEMP.SECRET),
							mobile_no: decryptionText(decodeData.mobile_no, TEMP.SECRET),
							profile_pic: decodeData.profile_pic,
						};
						localStorage.setItem('token', res.data.token);
						dispatch({
							type: LOGIN,
							payload: {
								isloggedin: res.data.token !== null || res.data.token != undefined ? true : false,
								auth_token: res.data.token,
								userData: userData,
								show_popup: res.data.show_popup,
							},
						});
						onSuccess && onSuccess(res.data, res.data.token);
					} else {
						onSuccess && onSuccess(res.data, res.data.token);
					}
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

// export const loginSelectedUser = (request, onSuccess, onError) => {
// 	return (dispatch) => {
// 		API.post(`/login/select-primary-user`, request)
// 			.then((res) => {
// 				if (res && res.data.status === '1') {
// 					setupUserLogin(res, dispatch);
// 					onSuccess && onSuccess(res.data);
// 				} else {
// 					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
// 				}
// 			})
// 			.catch((error) => {
// 				if (error && error.data) {
// 					onError && onError(error.data);
// 				} else {
// 					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
// 				}
// 			});
// 	};
// };

export const loginSelectedUser = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/login/select-primary-user-new`, request)
			.then((res) => {
				if (res && res.data.status === '1') {
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const logout = (onSuccess, onError) => {
	return (dispatch) => {
		persistor.flush();
		persistor.purge();

		dispatch({
			type: DESTROY_SESSION,
			payload: false,
		});

		dispatch({
			type: RESET_FEED,
		});

		dispatch({
			type: DESTROY_ROUTE,
		});

		dispatch({
			type: CLEAR_CART_USER_ALL,
		});

		dispatch({
			type: DESTROY_WISHLIST,
		});

		//dispatch({
		//type: DESTROY_CART,
		//});

		localStorage.removeItem('token');
		onSuccess && onSuccess();
	};
};

export const loginBySocialMail = (data, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/social-login`, data)
			.then((res) => {
				if (res && res.data.status === 1) {
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess({ ...res.data, should_redirect: res.data.profile_redirect });
				} else if (res && res.data.status === 2) {
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const registerBySocialMail = (data, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/social-register`, data)
			.then((res) => {
				if (res && res.data.status === '1') {
					const decodedUserData = decodeUserLoginToken(res.data.token)
          handleGoogleTag(GTAG_EVENT_REGISTER_SUCCESS,decodedUserData)
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess({ ...res.data, should_redirect: res.data.profile_redirect });
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const editUserProfile = (payload, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/edit-details`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					setupUserLogin(res, dispatch);
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};
