import Axios from 'axios';

import API from './API-EXPLICT';
import CMS_API from './API-CMS';
import { encryption, decryptionText, TEMP } from '../shared/cookieStore';
import * as ErrorMessages from '../Error/ErrorMessage/errorMessage';
import { addItemCategory, filterDataForGTag, handleGoogleTag, removeNullKeys } from './Common';
import { GTAG_EVENT_REPORT_DOWNLOADED, GTAG_EVENT_SELECT_ITEM, GTAG_EVENT_VIEW_ITEM } from './Constants';

export const getUserProfileDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/user-details`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const postLabReviews = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/rate-lab`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const postNpsReviews = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/nps-ratings`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const checkNpsReviews = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/check-nps-ratings`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const postSiteReviews = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/rate-site`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const postCartReviews = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/cart-rate-site`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCarePackageDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/care-packages-details`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCovidTests = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`feed/covid-tests`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCovidTestsPackages = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`feed/covid-tests-packages`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBlogListing = (page, search) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/blogs/?page=${page}&q=${search}`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBlogsByCategories = (permalink) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/blogs/category/${permalink}`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBlogDetails = (permalink) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/blog-details/${permalink}`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBlogCategories = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/category/blogs`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getEvents = (type, page, filter) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/events/${type}/?page=${page}&type=${filter}`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getEventDetails = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/event_details/${id}`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getHealthWithBenefits = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/lead-landing/health-benefit`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCurrentOffers = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/get-center-offers`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAnimities = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/center/get-aminities`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// Get About
export const getAbout = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/center/get-about`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// Get Services
export const getServices = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/center/get-service`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// get Center Image
export const getCenterImage = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/center/get-image`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getProductAccordion = (product_code) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/product-accordion/${product_code}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getLeadLandingProducts = (city_id, product_type) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/lead-landing/product/${city_id}/${product_type}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCarePackage = (payload) => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/care-packages`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const submitContactUs = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/contact-us`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getPrivacyPolicy = () => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/privacy_policy`)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getTermsOfUse = () => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/terms_of_use`)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getLabList = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/lab-list`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const requestCallback = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/request-call-back`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getRequestCallbackCityList = (payload) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/auto-popup-cities`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const uploadPrescriptions = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/upload-prescriptions`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const addFamilyMember = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/add-family`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getFamilyMemberDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/family-details`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const deleteFamilyMember = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/delete-family`, payload)
			.then((res) => {
				if (res && res.data.status == 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBannerImages = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/banner/1`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};
export const getBannerRnDImages = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/banner/25`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};
export const getContentDetails = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/rnd/get-rnd-content-tab`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};
export const getPartnerLogo = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/rnd/get-rnd-partner`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCategory = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/rnd/get-category-files`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOfferDiscountList = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/offers`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getStateList = () => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/state-list`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCityListByStateId = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/city-list`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCityListByStateIdLead = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/city-list-lead`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getTestimonials = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/testimonials`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAboutSrl = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/home/know_who_we_are`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const changePassword = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/change-password`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const deleteNotification = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/delete-notifications`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const deleteAllNotifications = (payload) => {
	return new Promise((resolve, reject) => {
		API.get(`/delete-all-notifications`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getPatietsSampleJopurney = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/home/patient_journey`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getTestCentres = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/covid/centers`)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getHomeSearchAutoSuggestData = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/home-search-autocomplete`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBannerByPageId = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/banner/${id}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getContentByPageId = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/page_content/${id}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOfficeAddresses = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/office_addresses`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getSocialLinks = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/social_links`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getInvestorsData = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/investors/documents/${id}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getBoardOfMembers = (id) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/investors/members/${id}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCovidData = (text) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/covid/${text}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCodeOfConduct = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/investors/code-of-conduct`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCorporatePhoneNumbers = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/corporate_phone_no`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getUserOrders = () => {
	return new Promise((resolve, reject) => {
		API.get(`/order/users`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOrderUsers = () => {
	return new Promise((resolve, reject) => {
		API.get(`/full-user-list`)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const downloadOrderReceipt = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/receipt`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAllCategoryOptions = () => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/all-categories`)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOrdersByUserId = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/list`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOrderDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/details`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					const data = decryptionText(res.data.data, TEMP.SECRET);
					if (data) {
						resolve(JSON.parse(data));
					} else {
						reject({});
					}
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getOrderStatus = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/status`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const trackOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/track`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const repeatOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/repeat`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const cancelOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/cancel`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const editOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/order/edit`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getEbsToken = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/ebs/payment`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getPaytmToken = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/paytm/payment`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const paytmCancelOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/paytm/cancel_order`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getReportsByUserId = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const downloadReportsReceipt = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/receipt`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const downloadReportsPdf = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/pdf`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					const tagData = {
						patient_id: decryptionText(payload.ptnt_id, TEMP.SECRET),
						Account_id: decryptionText(payload.ACC_ID, TEMP.SECRET),
						city: payload.city,
						mobile_no: payload.mobile_no,
					};
					handleGoogleTag(GTAG_EVENT_REPORT_DOWNLOADED, tagData);
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const downloadReportsSmart = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/smart`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					const tagData = {
						patient_id: decryptionText(payload.ptnt_id, TEMP.SECRET),
						Account_id: decryptionText(payload.ACC_ID, TEMP.SECRET),
						city: payload.city,
						mobile_no: payload.mobile_no,
					};
					handleGoogleTag(GTAG_EVENT_REPORT_DOWNLOADED, tagData);
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const shareReportsPdf = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/share`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getReportDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/details`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAllReports = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/reports/all-data`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getLabDetailsById = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/lab-details`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const shareLabLocationViaSMS = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/send-details-sms`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const editFamilyProfile = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/edit-family-details`, payload)
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getPackageDetails = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/product-details`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					try {
						const selectedPackageOrTestDetails = removeNullKeys(res.data.data);
						const selectedPackageOrTestDetailsPtnt = removeNullKeys(res.data.data_ptnt);
						// Merge the two objects while removing null values from each
						const mergedDetails = {
							...selectedPackageOrTestDetails,
							...selectedPackageOrTestDetailsPtnt,
						};
						const argumentsToSelect = [{ ID: 'item_id', PRDCT_ALIASES: 'item_name', CATEGORY: 'item_category', PRICE: 'price' }];
						const filteredData = filterDataForGTag([mergedDetails], argumentsToSelect);
						const productDataWithBrand = addItemCategory(filteredData, false, 1);
						const totalPrice = productDataWithBrand.reduce((acc, product) => acc + (product.price || 0), 0);
						handleGoogleTag(GTAG_EVENT_VIEW_ITEM, {
							currency: 'INR',
							value: totalPrice,
							items: productDataWithBrand,
						});
						handleGoogleTag(GTAG_EVENT_SELECT_ITEM, {
							currency: 'INR',
							value: totalPrice,
							items: productDataWithBrand,
						});
					} catch (err) {
						console.log({ ErrorMessages: err });
					}
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAllHomePackages = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/home-packages`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAllHomeTests = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/home-tests`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getNavbarMessage = (payload) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/messages/${payload}`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getFooterReachUs = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/cms/footer_address`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const checkOptIn = () => {
	return new Promise((resolve, reject) => {
		API.get(`/check-opt-in`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const optInNotification = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/whatsapp/opt_in_out`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const becomeBusinessPartner = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/partner`, payload)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const becomeVendor = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/vendor`, payload)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getLabRating = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/feed/lab-rating`, payload)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getGeneralLabTimings = () => {
	return new Promise((resolve, reject) => {
		API.get(`/feed/general_lab_timings`)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAvailablePromoCodes = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/available-promocode`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getAvailableSrlPoints = () => {
	return new Promise((resolve, reject) => {
		API.get(`/cart/available-points`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getUserPoints = () => {
	return new Promise((resolve, reject) => {
		API.post(`/user-points`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// export const getSrlPointsOTP = (payload) => {
//     return new Promise((resolve,reject)=>{
//         API.post(`/cart/check-available-points`,payload)
//         .then(res => {
//             if(res && res.data.status === "1"){
//                 resolve(res.data);
//             }else{
//                 reject({message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER})
//             }
//         }).catch(error=>{
//             console.log("ERROR:", error);
//             reject(error.data);
//         })
//     })
// }

export const getAdditionalCharges = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/additional-charges`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCartId = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/create-cart`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const generateOrder = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/generate-order`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const cashOnDelivery = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/cash-on-delivery`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// export const getSchedule = (payload) => {
//     return new Promise((resolve,reject)=>{
//         API.post(`/cart/get-schedule`,payload)
//         .then(res => {
//             if(res && res.data.status === "1"){
//                 resolve(res.data);
//             }else{
//                 reject({message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER})
//             }
//         }).catch(error=>{
//             console.log("ERROR:", error);
//             reject(error.data);
//         })
//     })
// }

export const getSchedule = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/get-schedule-new`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getCenterListing = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/center-listing`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// Addresses of all the users sent in payload.
export const getUserAddresses = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/user-addresses`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getPPTestProducts = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/pp-test`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const productCityChecking = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/cart/product-city-checking`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getUserTranscationDetails = () => {
	return new Promise((resolve, reject) => {
		API.post(`/user-transaction-details`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const forgotPasswordVerify = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/forgot-password/verify`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const forgotPassword = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/forgot-password`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getUserAddressList = () => {
	return new Promise((resolve, reject) => {
		API.get(`/user-addresses`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const addUserAddress = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/user-addresses`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const updateUserAddress = (payload) => {
	return new Promise((resolve, reject) => {
		API.put(`/user-addresses`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const deleteUserAddress = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/user-addresses-remove`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// export const getUserGeoLocation = () => {
//     return new Promise((resolve,reject)=>{
//         Axios.get(`http://www.geoplugin.net/json.gp`)
//         .then(res => {
//             if(res.data.geoplugin_status === 200){
//                 resolve(res.data);
//             }else{
//                 reject({message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER})
//             }
//         }).catch(error=>{
//             console.log("ERROR:", error);
//             reject(error.data);
//         })
//     })
// }

export const getUserGeoLocation = () => {
	return new Promise((resolve, reject) => {
		Axios.get(`https://freegeoip.app/json/`)
			.then((res) => {
				if (res.status === 200) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

// dynamic landing page
export const addDynamicLandingPageForm = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/landing-form-submit`, payload)
			.then((res) => {
				if (res && res.data.status == '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};

export const getDynamicLandingOtp = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/lead-landing/get-otp`, payload)
			.then((res) => {
				if (res && res.data.status == '1') {
					resolve(res.data);
				} else {
					console.log('second');
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				console.log('ERROR:', error);
				reject(error.data);
			});
	});
};

export const getDynamicLandingVerifyOtp = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/lead-landing/verify-otp`, payload)
			.then((res) => {
				if (res && res.data.status == '1') {
					resolve(res.data);
				} else {
					console.log('second');
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				console.log('ERROR:', error);
				reject(error.data);
			});
	});
};
export const getDynamicLandingResendOtp = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/lead-landing/resend-otp`, payload)
			.then((res) => {
				if (res && res.data.status == '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				console.log('ERROR:', error);
				reject(error.data);
			});
	});
};

export const getDynamicLanding = (payload) => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/llp/get-lead-landing-page/${payload}`)
			.then((res) => {
				if (res && res.data.status == '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				console.log('ERROR:', error);
				reject(error.data);
			});
	});
};

export const getDoctor = () => {
	return new Promise((resolve, reject) => {
		CMS_API.get(`/home/doctor-list`)
			.then((res) => {
				if (res && res.data.status === 1) {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};
export const generateOrdericic = (payload) => {
	return new Promise((resolve, reject) => {
		API.post(`/icici/payment`, payload)
			.then((res) => {
				console.log('res:', res);
				if (res && res.data.status === '1') {
					resolve(res.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					reject(error.data);
				} else {
					reject({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	});
};
