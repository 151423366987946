import {
    REGISTRATION_SUCCESS,
    GET_OTP_SUCCESS ,
    GET_OTP_FAIL,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAIL,
    REGISTRATION_FAIL,
    LOGIN,
    OPT_IN,
    DESTROY_SESSION,
    UPDATE_USER_SRL_POINTS,
    UPDATE_USER_SRL_POINTS_CART
} from '../action-types/action.types';

import isEmpty from 'lodash/isEmpty';

const initialStates={
    isloggedin:false,
    otpId:'',
    show_popup:false,
    email:'',
    mobile_no:'',
    errorMsg:'',
    user_id:'',
    auth_token:'',
    register_option:'',
    registerResponse:{},
    data:{},
    userData:{}
}

const auth = (state= initialStates, action)=>{
    switch (action.type) {
        case GET_OTP_SUCCESS:
            return {
                ...state,
                mobile_no: action.payload
            }    
        case GET_OTP_FAIL:
            return {
                ...state,
                errorMsg: action.payload
            }
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case VERIFY_OTP_FAIL:
            return {
                ...state,
                errorMsg: action.payload
            }
        case REGISTRATION_SUCCESS: return {
            ...state,
            registerResponse: action.payload,
        }
        case REGISTRATION_FAIL:
            return {
                errorMsg: action.payload
            }
        case LOGIN: 
        return{
            ...state,
            isloggedin: action.payload.isloggedin,
            auth_token: action.payload.auth_token,
            userData: action.payload.userData,
            show_popup:action.payload.show_popup,
        }
        case UPDATE_USER_SRL_POINTS: 
        return{
            ...state,
            userData: state.userData && !isEmpty(state.userData) ? { ...state.userData, ptnt_points : action.payload.points_left } : {} 
        }
        case UPDATE_USER_SRL_POINTS_CART: 
        return{
            ...state,
            userData: state.userData && !isEmpty(state.userData) ? { ...state.userData, ptnt_points : action.payload.points_left } : {} 
        }
        case OPT_IN: 
        return{
            ...state,
            show_popup:action.payload.show_popup
        }
        case DESTROY_SESSION: 
        return{
            state:undefined
        }
        default: return state;
    }
       
}

export default auth;
