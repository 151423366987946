
const initialStates={
    notification_data:[],
    offer_data:[]
}

const wishlist = (state= initialStates, action)=>{
    switch (action.type) {
        case 'GOT_NOTIFICATION_DATA':{
            return {
                notification_data: action.payload,
                offer_data: state.offer_data || []
            }
        }

        case 'GOT_OFFER_DATA':{
            return {
                offer_data: action.payload,
                notification_data:state.notification_data || []
            }
        }
      
        case 'DELETE_NOTIFICATION':{

            let wishlist_data = [ ...state.wishlist_data ];
            // console.log("before delete",wishlist_data)

            wishlist_data = wishlist_data.filter(item => item.ID != action.payload )
            // console.log("after delete",wishlist_data)
            return {
                wishlist_data
            }
        }
        
        default: return state;
    }
       
}

export default wishlist;
