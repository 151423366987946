import axios from 'axios';
import { logout } from '../action/auth';
import { store } from '../store/index';
import { toast } from 'react-toastify';

const { dispatch } = store;

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

async function getNewAccessToken(token, refresh_token) {
	return axios.post(
		process.env.REACT_APP_API_URL + '/token/regen',
		{
			token: token,
		},
		{
			headers: { Authorization: 'Bearer ' + refresh_token },
		}
	);
}

const savetoken = async ({ token, refresh_token }) => {
	try {
		localStorage.setItem('token', token);
		localStorage.setItem('refresh_token', refresh_token);
	} catch (e) {
		console.error('Token Error: ', e);
	}
};

// Add a request interceptor
instance.interceptors.request.use(
	(config) => {
		// Insert authorization token on request call
		let token = process.env.REACT_APP_EXPLICIT_TOKEN;
		let local_store_token = localStorage.getItem('token');

		if (local_store_token && typeof local_store_token != undefined && local_store_token != '') {
			token = local_store_token;
		}

		config.headers['Authorization'] = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Updated response interceptor
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (error.response && error.response.status === 429) {
			toast.error(error.response.data.message);
		}
		if (error.response && error.response.status === 401) {
			dispatch(logout());
			window.location.href = '/';
			return Promise.reject(error.response);
		} else {
			return Promise.reject(error.response);
		}
	}
);

// Add a response interceptor
// instance.interceptors.response.use(
// 	(response) => {
// 		return response;
// 	},
// 	async (error) => {
// 		let local_store_token = localStorage.getItem('token');
// 		let local_store_refresh_token = localStorage.getItem('refresh_token');
// 		const originalRequest = error.config;

// 		if (error.response && error.response.status === 401) {
// 			if(local_store_token && local_store_refresh_token){
// 				originalRequest._retry = true;
// 				try {
// 					const res = await getNewAccessToken(local_store_token, local_store_refresh_token);
// 					if (res && res.data.token) {
// 						await savetoken(res.data);
// 						axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
// 						return instance(originalRequest);
// 					} else {
// 						dispatch(logout());
// 						window.location.href = '/';
// 						return Promise.reject(error.response);
// 					}
// 				} catch {
// 					dispatch(logout());
// 					window.location.href = '/';
// 					return Promise.reject(error.response);
// 				}
// 			}else{
// 				dispatch(logout());
// 				window.location.href = '/';
// 				return Promise.reject(error.response);
// 			}
// 		} else {
// 			return Promise.reject(error.response);
// 		}
// 	}
// );

export default instance;
