import {
    REGISTRATION_FAMILY_SUCCESS,
    GET_FAMILY_OTP_SUCCESS ,
    GET_FAMILY_OTP_FAIL,
    VERIFY_FAMILY_OTP_SUCCESS,
    VERIFY_FAMILY_OTP_FAIL,
    REGISTRATION_FAMILY_FAIL,
} from '../action-types/action.types';

const initialStates={
    otpId:'',
    email:'',
    mobile_no:'',
    errorMsg:'',
    user_id:'',
    auth_token:'',
    register_option:'',
    registerResponse:{},
    data:{},
    userData:{}
}

const auth = (state= initialStates, action)=>{
    switch (action.type) {
        case GET_FAMILY_OTP_SUCCESS:
            return {
                mobile_no: action.payload
            }    
        case GET_FAMILY_OTP_FAIL:
            return {
                errorMsg: action.payload
            }
        case VERIFY_FAMILY_OTP_SUCCESS:
            return {
                data: action.payload
            }
        case VERIFY_FAMILY_OTP_FAIL:
            return {
                errorMsg: action.payload
            }
        case REGISTRATION_FAMILY_SUCCESS: return {
            ...state,
            registerResponse: action.payload,
        }
        case REGISTRATION_FAMILY_FAIL:
            return {
                errorMsg: action.payload
            }
        default: return state;
    }
       
}

export default auth;
