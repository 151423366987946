import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import Swal from 'sweetalert2';
import { addAuthTimer, removeAuthTimer } from '../action/route.action';
import { logout } from '../action/auth';

import { GENDERS_FORM, GTAG_EVENT, GTAG_EVENT_ADD_TO_CART, GTAG_EVENT_BEGIN_CHECKOUT, GTAG_EVENT_PURCHASE, GTAG_EVENT_VIEW_CART, LOGIN_TIMEOUT_VALUE, REGEX_DIGITS } from './Constants';
import { camelCase, readFileAsArrayBuffer } from './Helper';
import jwtDecode from 'jwt-decode';
import { TEMP, decryptionText } from './cookieStore';

export const getCityIdByName = (cityName, cityList) => {
	if (cityName && cityList && cityList.length > 0) {
		const selectedCity = find(cityList, (cityDetails) => cityDetails.city_name.toLowerCase() === cityName.toLowerCase());
		if (selectedCity && !isEmpty(selectedCity)) {
			return selectedCity.value;
		}
	}
	return '';
};

export const getCityDetailsByName = (cityName, cityList) => {
	if (cityName && cityList && cityList.length > 0) {
		const selectedCity = find(cityList, (cityDetails) => cityDetails.city_name.toLowerCase() === cityName.toLowerCase());
		if (selectedCity && !isEmpty(selectedCity)) {
			return selectedCity;
		}
	}
	return '';
};

export const getCityDetailsById = (cityId, cityList) => {
	if (cityId && cityList && cityList.length > 0) {
		const selectedCity = find(cityList, (cityDetails) => cityDetails.value == cityId);
		if (selectedCity && !isEmpty(selectedCity)) {
			return selectedCity;
		}
	}
	return '';
};

export const validateFilesFormat = async (files, supportedFormats, regexExt) => {
	if (files && supportedFormats && regexExt) {
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			const isValidExtension = regexExt.test(file.name); // validate extension as JPEG, JFIF,etc has same signature and mime
			const formatDetails = find(supportedFormats, { mime: file.type }); // validate mime
			if (file && isValidExtension && formatDetails && !isEmpty(formatDetails)) {
				const data = await readFileAsArrayBuffer(file);
				const arr = new Uint8Array(data).subarray(0, 4);
				let fileSignature = '';
				for (let i = 0; i < arr.length; i++) {
					fileSignature += arr[i].toString(16);
				}
				const isValidSignature = formatDetails.signature.includes(fileSignature); // validate signature
				if (!isValidSignature) {
					//Unsupported (No need to check further)
					return false;
				}
				//Supported (Keep on checking rest of the files)
			} else {
				// Unsupported (No need to check further)
				return false;
			}
		}
		// All Files are Supported
		return true;
	}
	return false;
};

export const validateFilesSize = (files, maxSize) => {
	if (files && maxSize) {
		for (let index = 0; index < files.length; index++) {
			if (files[index] && files[index].size <= maxSize) {
				// Supported
			} else {
				// Unsupported (No need to check further)
				return false;
			}
		}
		return true; // All Supported
	}
	return false;
};

export const validateFilesCount = (files, maxCount) => {
	if (files && files.length <= maxCount) {
		return true;
	}
	return false;
};

export const numberFieldValidation = (value) => {
	if (value.slice(-1)) {
		if (value.charAt(0) !== '0') {
			if (!REGEX_DIGITS.test(value.slice(-1)) || value.length > 10 || parseInt(value.slice(-1) === 0)) {
				let newValue = value.slice(0, value.length - 1);
				return newValue;
			} else {
				return value;
			}
		} else {
			let newValue = value.slice(1, value.length);
			return newValue;
		}
	} else {
		return '';
	}
};

export const selectGender = (gender) => {
	if (gender) {
		const selectedGender = find(GENDERS_FORM, { value: gender });
		if (selectedGender && !isEmpty(selectedGender)) {
			return selectedGender;
		}
	}
	return { label: '', value: '' };
};

export const selectState = (state, stateList) => {
	if (stateList && stateList.length > 0) {
		const selectedState = find(stateList, (stateDetails) => stateDetails.label.toLowerCase().trim() === state.toLowerCase().trim());
		if (selectedState && !isEmpty(selectedState)) {
			return selectedState;
		}
	}
	return '';
};

export const selectCity = (city, cityList) => {
	// console.log('city:', city.toLowerCase().trim());
	// console.log('cityList:', cityList);
	const selectedCity = find(
		cityList,
		(cityDetails) =>
			// (cityDetails.aliases && cityDetails.aliases.toLowerCase().trim() === city.toLowerCase().trim()) ||
			cityDetails.label.toLowerCase().trim() === city.toLowerCase().trim()
	);
	// console.log('>>>>>>>', selectedCity);
	if (selectedCity && !isEmpty(selectedCity)) {
		return selectedCity;
	}
	return '';
};

// export const attachChatBot = () => {
// 	let script = document.createElement('script');
// 	script.id = 'srlclient-iframe';
// 	script.src = 'https://srlclientsit.ochumanoid.ai//assets/javascript/ifrm.js';
// 	script.async = true;
// 	document.body.appendChild(script);
// };

export const attachChatBot = () => {
	let URL = 'https://srlclientsit.ochumanoid.ai'; //SIT
	// let URL = 'https://srldevclient.ochumanoid.ai' //UAT
	// let URL =  'https://srlclientlive.ochumanoid.ai' //PROD

	var script_tag_id = document.getElementById('oc_chat');
	var query = script_tag_id ? script_tag_id.src.replace(/^[^\?]+\??/, '') : '';
	// Parse the querystring into arguments and parameters
	var vars = query.split('&');
	var args = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		// decodeURI doesn't expand "+" to a space.
		args[pair[0]] = decodeURI(pair[1]).replace(/\+/g, ' ');
	}
	var token = args['token'] ? args['token'] : 'jcc';
	const isPresent = document.getElementById('chat');
	if (token.trim().length > 0 && !isPresent) {
		var container = document.createElement('div');
		container.setAttribute('id', 'chat');
		container.setAttribute('class', 'mkclient-show');
		var ifrm = document.createElement('iframe');
		ifrm.setAttribute('id', 'mkclient-iframe');
		ifrm.style.background = 'transparent';
		ifrm.setAttribute('src', URL);
		ifrm.onload = () => {
			ifrm.contentWindow.postMessage(JSON.stringify({ token: token, host: window.top.location.href }), '*');
		};
		var link = document.createElement('link');
		link.id = 'chatbot-style';
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = URL + '/assets/css/style.css';
		container.appendChild(ifrm);
		document.body.appendChild(container);
		document.body.appendChild(link);
		localStorage.setItem('__CCK', 'true');
		document.cookie = '__CCK = true; secure';
		document.cookie = 'asaWEW=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
		document.cookie = 'asaWEW=false';
		window.addEventListener('message', receiveMessage, false);
	} else {
		// alert("Invalid request.");
	}

	function receiveMessage(e) {
		// console.log('received', e);
		if (e.data && e.origin == URL) {
			if (e.data.indexOf('notAllowed') > -1) {
				var notAllowed = JSON.parse(e.data);
				//console.log('received',notAllowed['notAllowed']);
				//let frameEl = document.getElementById('mkclient-iframe');
				//frameEl.contentWindow.document.body.innerHTML = notAllowed['notAllowed'];
			} else {
				document.getElementById('chat').className = '';
				var data = e.data.split(' ');
				//if(data.indexOf("mkclient-show") > -1 || data.indexOf("slide-fwd-center") > -1 || data.indexOf("mkclient-show1") > -1 || data.indexOf("mkclient-hide") > -1)
				document.getElementById('chat').classList.add(...data);
				//}
			}
		}
	}
};

export const detachChatBot = () => {
	let chatbot = document.getElementById('chat');
	let script = document.getElementById('srlclient-iframe');
	let css = document.getElementById('chatbot-style');
	if (chatbot) {
		chatbot.remove();
	}
	if (script) {
		script.remove();
	}
	if (css) {
		css.remove();
	}
};

export const adjustSliderSettings = (defaultSettings, settings, setSettings, list) => {
	if (list && list.length > 0) {
		let newSettings = { ...settings };
		if (list.length <= defaultSettings.slidesToShow) {
			newSettings.slidesToShow = list.length;
			newSettings.slidesToScroll = list.length;
		} else if (list.length >= defaultSettings.slidesToShow) {
			newSettings.slidesToShow = defaultSettings.slidesToShow;
			newSettings.slidesToScroll = defaultSettings.slidesToScroll;
		}
		if (newSettings.slidesToShow != settings.slidesToShow) {
			setSettings(newSettings);
		}
	}
};

export const handleGtagEvent = (event, options, items) => {
	// console.log('options:', options)
	// console.log('event:=====================================', event);
	if (window && window.gtag) {
		// console.log({event,options,items})
		if (options && !isEmpty(options)) {
			// If Event type has items then sanitize the items.
			if (event === GTAG_EVENT_ADD_TO_CART) {
				if (items && items.length > 0) {
					const sanitizedItems = [];
					const totalPrice = items.reduce((acc, product) => acc + (product.PRICE || 0), 0);
					items.forEach((item) => {
						sanitizedItems.push({
							item_id: item.ID,
							item_name: item.PRDCT_ALIASES,
							item_category: item.CATEGORY,
							item_quantity: options.quantity,
							item_price: item.PRICE,
							item_brand: 'DDRC',
							item_discount: item.DISC,
							coupon: item.OFR_CD,
							city: item.CITY,
							state: item.STATE,
						});
					});
					window.gtag(GTAG_EVENT, event, {
						currency: 'INR',
						value: totalPrice,
						items: sanitizedItems,
					});
				}
			} else if (event === GTAG_EVENT_PURCHASE) {
				const sanitizedItems = [];
				items.forEach((item) => {
					sanitizedItems.push({
						id: item.ID,
						name: item.PRDCT_ALIASES,
						category: item.CATEGORY,
						quantity: options.quantity,
						price: item.PRICE,
					});
				});
				window.gtag(GTAG_EVENT, event, {
					transaction_id: options.transaction_id,
					// affiliation: '',
					value: options.value,
					currency: options.currency,
					// tax: 0,
					// shipping: 0,
					items: sanitizedItems,
				});
			}
			 else if (event == 'handle_gtag_checkout_initiated') {
				window.gtag('event', 'conversion', {
					send_to: 'AW-328836092/uEKOCJvlp5UYEPzH5pwB',
				});
				
			} else if (event === 'whatsapp_submit') {
				window.gtag('event', 'Whatsapp click', {
					send_to: 'AW-328836092/YWwaCJO7wJMYEPzH5pwB',
				});
			} else if (event === 'customer_care_call') {
				window.gtag('event', 'Customer Care Call', {
					send_to: 'AW-328836092/YWwaCJO7wJMYEPzH5pwB',
				});
			} else if (event === 'missed_call') {
				window.gtag('event', 'Home Collection Missed Call', {
					send_to: 'AW-328836092/YWwaCJO7wJMYEPzH5pwB',
				});
			} else {
				window.gtag(GTAG_EVENT, event, options);
			}
		}
	}
};

export const handleGA4Event = (event, options, items) => {
	if (window && window.gtag) {
		// console.log({ event, options, items });
		// console.log({event,options,items})
		if (options && !isEmpty(options)) {
			// If Event type has items then sanitize the items.
			if ([GTAG_EVENT_ADD_TO_CART, GTAG_EVENT_VIEW_CART, GTAG_EVENT_BEGIN_CHECKOUT].includes(event)) {
				if (items && items.length > 0) {
					const sanitizedItems = [];
					const totalPrice = items.reduce((acc, product) => acc + (product.PRICE || 0), 0);
					items.forEach((item) => {
						sanitizedItems.push({
							item_id: item.ID,
							item_name: item.PRDCT_ALIASES,
							item_category: item.CATEGORY,
							price: item.PRICE,
							item_brand: 'DDRC',
							...(event !== GTAG_EVENT_VIEW_CART && { quantity: options.quantity }),
							discount: item.DISC,
							coupon: item.OFR_CD,
							// city: item.CITY,
							// state: item.STATE,
						});
					});
					window.gtag(GTAG_EVENT, event, {
						currency: 'INR',
						value: totalPrice,
						...(event == GTAG_EVENT_BEGIN_CHECKOUT && { coupon: items[0].OFR_CD }),
						items: sanitizedItems,
					});
					// window.gtag(GTAG_EVENT, 'conversion', {
					// 	sent_to: 'AW-328836092/YWwaCJO7wJMYEPzH5pwB',
					// 	items: sanitizedItems,
					// });
				}
			} else if (event === 'view_cart') {
			} else {
				window.gtag(GTAG_EVENT, event, options);
			}
		}
	}
};
export const handleGoogleTag = (eventName, items) => {
	console.log({ eventName });
	if (eventName) {
		window.gtag('event', eventName, items);
	}
};

export const setLoginSessionTimer = (timer, dispatch, timeout) => {
	const timeoutValue = timeout >= 0 ? timeout : LOGIN_TIMEOUT_VALUE;
	let newTimer;
	dispatch(removeAuthTimer(timer));
	newTimer = setTimeout(() => {
		//   let token = localStorage.getItem('token');
		//   if(token){
		// 	dispatch(logout());
		// 	showSwalTimer();
		//   }else{

		//   }
		dispatch(logout());
		showSwalTimer();
	}, timeoutValue);
	dispatch(addAuthTimer(newTimer));
};

export const showSwalTimer = () => {
	let swalTimerInterval;
	const isHomePage = window.location.pathname === '/';
	Swal.fire({
		title: 'Your session has expired',
		html: isHomePage
			? 'Page will reload in <strong></strong> seconds<br/><br/>'
			: 'You will be redirected to the home page in <strong></strong> seconds<br/><br/>',
		timer: 10000,
		didOpen: () => {
			swalTimerInterval = setInterval(() => {
				Swal.getHtmlContainer().querySelector('strong').textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
			}, 100);
		},
		willClose: () => {
			clearInterval(swalTimerInterval);
			window.location.href = '/';
		},
	}).then(() => {
		clearInterval(swalTimerInterval);
		window.location.href = '/';
	});
};

export const selectCityCollectionType = (cartInfo, selected_lab_center, user_address) => {
	if (cartInfo && !cartInfo.home_collection && cartInfo.lab_visit) {
		if (selected_lab_center && !isEmpty(selected_lab_center)) {
			return camelCase(selected_lab_center.CITY);
		}
	} else {
		if (user_address && !isEmpty(user_address)) {
			return user_address.address.CITY;
		} else {
			return '';
		}
	}
};

export const selectedStateCollectionType = (cartInfo, selected_lab_center, user_address) => {
	if (cartInfo && !cartInfo.home_collection && cartInfo.lab_visit) {
		if (selected_lab_center && !isEmpty(selected_lab_center)) {
			return camelCase(selected_lab_center.STATE);
		}
	} else {
		if (user_address && !isEmpty(user_address)) {
			return user_address.address.STATE;
		} else {
			return '';
		}
	}
};

export const decodeUserLoginToken = (token) => {
	const decodeData = jwtDecode(token);
	const userDetails = {
		...(decryptionText(decodeData.email, TEMP.SECRET) != '-' && { email: decryptionText(decodeData.email, TEMP.SECRET) }),
		name: decryptionText(decodeData.full_name, TEMP.SECRET),
		...(decryptionText(decodeData.mobile_no, TEMP.SECRET) != '-' && { mobile_no: decryptionText(decodeData.mobile_no, TEMP.SECRET) }),
	};
	return userDetails;
};

export const convertFormDataToObject = (data) => {
	const formDataObject = {};
	for (const [key, value] of data.entries()) {
		formDataObject[key] = value;
	}
	return formDataObject;
};
export const removeNullKeys = (obj) => {
	if (!obj) {
		return '';
	}
	for (const key in obj) {
		if (obj[key] === null) delete obj[key];
	}
	return obj;
};

export const arrayOfObjectKeysSmall = (data) => {
	const arrayOfObjectWithSmallKeys =
		Array.isArray(data) &&
		data.length > 0 &&
		data.map((el) => {
			const newObject = {};
			for (let key in el) {
				newObject[key.toLowerCase()] = el[key];
			}
			return newObject;
		});
	return arrayOfObjectWithSmallKeys;
};
export const objectKeysSmall = (data) => {
	const newObject = {};
	for (let key in data) {
		newObject[key.toLowerCase()] = data[key];
	}
	return newObject;
};

export const filterDataForGTag = (data, argumentsToSelect) => {
	return data.map((item) =>
		argumentsToSelect.reduce(
			(filteredItem, argumentObj) => ({
				...filteredItem,
				...Object.entries(argumentObj)
					.filter(([key]) => item.hasOwnProperty(key))
					.reduce((obj, [key, alias]) => ({ ...obj, [alias]: item[key] }), {}),
			}),
			{}
		)
	);
};
export const addItemCategory = (data, category, quantity) => {
	return data.map((item) => {
		return {
			...item,
			...(!!category && { item_category: category }),
			...(!!quantity && { quantity: quantity }),
			item_brand: 'DDRC',
		};
	});
};
