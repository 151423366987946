import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { encryptionStringify, TEMP } from '../shared/cookieStore';

import {
	ADD_TO_CART,
	REMOVE_FROM_CART,
	CART_PRODUCT_CITY_CHECKING,
	UPDATE_CART_PRODUCTS,
	SET_CART_USERS_LIST,
	SET_CART_SELECTED_USER,
	RESET_CART_SELECTED_USER,
	SET_CART_SOMEONE_ELSE_LIST,
	SET_CART_USER_ADDRESS,
	CART_APPLY_PROMO_CODE,
	CART_RESET_PROMO_CODE,
	CART_SET_SRL_POINTS,
	CART_VERIFY_SRL_POINTS,
	CART_RESET_SRL_POINTS,
	CART_EXPIRE_SRL_POINTS,
	SET_CART_PP_TEST_OPTION,
	SET_CART_HARD_COPY_OPTION,
	SET_CART_SELECTED_LAB_CENTER,
	SET_COMBINED_CART_PRODUCTS,
	SET_CART_BILL_DETAILS,
	CLEAR_CART_USER_ALL,
	DESTROY_CART,
	SET_SAMPLE_COLLECTION_TYPE_OPTION,
} from '../action-types/action.types';
import API from '../shared/API-EXPLICT';
import * as ErrorMessages from '../Error/ErrorMessage/errorMessage';
import { GTAG_EVENT_ADD_TO_CART, GTAG_EVENT_REGISTER_SUCCESS, PP_TEST_ID } from '../shared/Constants';
import { addItemCategory, decodeUserLoginToken, filterDataForGTag, handleGA4Event, handleGoogleTag, handleGtagEvent } from '../shared/Common';

export const addToCart = (payload) => {
	return (dispatch, getState) => {
		// If Adding PP-TEST product then also add pp selection
		const pp_test = getState().cart.pp_test;
		if (pp_test === undefined || isEmpty(pp_test) || isEmpty(pp_test.product)) {
			// If PP Test is not already present in cart
			if (payload && payload.length > 0) {
				const pp_test_product = find(payload, { ID: PP_TEST_ID });
				if (pp_test_product && !isEmpty(pp_test_product)) {
					dispatch(setPPTestOption({ product: pp_test_product, selected: true }));
				}
			}
		}
		// Handle Gtag Event (New Product Is at the end of array)
		try{
			const argumentsToSelect = [
				{ ID: 'item_id', PRDCT_ALIASES: 'item_name', CATEGORY: 'item_category', PRICE: 'price' },
			];
			const filteredData = filterDataForGTag(payload, argumentsToSelect)
			const productDataWithBrand = addItemCategory(filteredData, false, 1)
			const totalPrice = productDataWithBrand.reduce((acc, product) => acc + (product.price || 0), 0);
			handleGoogleTag(GTAG_EVENT_ADD_TO_CART, {
				currency: 'INR',
				value: totalPrice,
				items: productDataWithBrand
			});
		}
		catch(err){
			console.log({err})
		}
		// Handle Gtag Event (New Product Is at the end of array)
		// handleGtagEvent(GTAG_EVENT_ADD_TO_CART, { quantity: 1 }, [payload[payload.length - 1]]);
		// handleGA4Event(GTAG_EVENT_ADD_TO_CART, { quantity: 1 }, [payload[payload.length - 1]]);
		// Reset SRL Points
		dispatch(cartResetSrlPoints());
		// Reset Promo Code
		dispatch(cartResetPromoCode());
		// Show Toast
		toast.info('Added to cart');
		// Add To Store
		dispatch({
			type: ADD_TO_CART,
			payload: payload,
		});
	};
};

export const removeFromCart = (payload) => {
	return (dispatch, getState) => {
		// If removing PP-TEST product then also remove pp selection
		const pp_test = getState().cart.pp_test;
		if (pp_test && !isEmpty(pp_test) && pp_test.selected) {
			if (payload == pp_test.product.ID) {
				dispatch(setPPTestOption({ product: {}, selected: false }));
			}
		}
		// Reset SRL Points
		dispatch(cartResetSrlPoints());
		// Reset Promo Code
		dispatch(cartResetPromoCode());
		// Show Toast
		toast.info('Removed from cart');
		// Remove From Store
		dispatch({
			type: REMOVE_FROM_CART,
			payload: payload,
		});
	};
};

export const destroyCart = () => {
	return (dispatch) => {
		dispatch({
			type: 'DESTROY_CART',
		});
	};
};

export const clearCartUserData = () => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_CART_USER_ALL',
		});
	};
};

export const cartProductCityChecking = (payload, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/cart/product-city-checking`, payload)
			.then((res) => {
				if (res && res.data.status === '1') {
					dispatch({
						type: CART_PRODUCT_CITY_CHECKING,
						payload: { new_cart_data: res.data.data },
					});
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError(res.data);
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const updateCartProducts = (payload, onSuccess, onError) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CART_PRODUCTS,
			payload: { new_cart_data: payload },
		});
	};
};

export const setCartUsersList = (onSuccess, onError) => {
	return (dispatch) => {
		API.get(`/full-user-list`)
			.then((res) => {
				if (res && res.data.status === '1') {
					dispatch({
						type: SET_CART_USERS_LIST,
						payload: { users_list: res.data.data },
					});
					onSuccess && onSuccess(res.data.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const setCartSomeoneElseList = (request, onSuccess, onError) => {
	return (dispatch) => {
		API.post('/register', request)
			.then((res) => {
				if (res && res.data.status === '1') {
					const decodedUserData = decodeUserLoginToken(res.data.token)
          handleGoogleTag(GTAG_EVENT_REGISTER_SUCCESS,decodedUserData)
					dispatch({
						type: SET_CART_SOMEONE_ELSE_LIST,
						payload: { someone_else: res.data.data },
					});
					// console.log("someone redux action data",res.data.data)
					onSuccess && onSuccess(res.data.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const setCartSelectedUser = (payload, isLoggedUser) => {
	return (dispatch) => {
		dispatch(cartResetPromoCode());
		dispatch({
			type: SET_CART_SELECTED_USER,
			payload: { selected_user: payload, isLoggedUser },
		});
	};
};

export const resetCartSelectedUser = (payload) => {
	return (dispatch) => {
		dispatch(cartResetPromoCode());
		dispatch({
			type: RESET_CART_SELECTED_USER,
			payload: { selected_user: payload },
		});
	};
};

export const setCartUserAddress = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_CART_USER_ADDRESS,
			payload: { user_address: payload },
		});
	};
};

export const cartApplyPromoCode = (payload, canApplyPromoCode, onSuccess, onError) => {
	return (dispatch) => {
		const encryptedPostData = {
			promo_info: encryptionStringify(payload, TEMP.SECRET),
		};
		API.post(`/cart/apply-promocode`, encryptedPostData)
			.then((res) => {
				if (res && res.data.status === '1') {
					if ((res.data.valid && res.data.cart_msg === 'APPLICABLE') || res.data.cart_msg === 'APPLICABLE') {
						//old logic 2
						if ((res.data.discount && res.data.discount >= 0) || res.data.discount >= 0) {
							const promoCodeDetails = {
								promocode: payload.promocode,
								discount: res.data.discount,
							};
							const newDiscountAmount = canApplyPromoCode(payload.total_grand_amount_round, promoCodeDetails.discount);
							// if (newDiscountAmount && newDiscountAmount > 0) {
							// const newDiscountAmount = getMaxPromoDiscount(promoCodeDetails.discount);
							dispatch({
								type: CART_APPLY_PROMO_CODE,
								payload: { appliedPromo: { ...promoCodeDetails, discount: newDiscountAmount } },
							});
							onSuccess && onSuccess(res.data);
							// } else {
							// 	onError && onError({ message: 'Applied SRL Club points and Promo Code together reached max discount value' });
							// }
						}
						// else if(res.data.cart_msg === "NOT APPLICABLE"){
						//     onError && onError({message: "Promo Code not applicable for current cart items"});
						// }
						else {
							onError && onError({ message: 'Invalid Promo Code' });
						}
					} else {
						onError && onError({ message: 'Invalid Promo Code' });
					}
				} else {
					onError && onError({ message: 'Something went wrong! Please try again later' });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const cartResetPromoCode = () => {
	return (dispatch) => {
		dispatch({
			type: CART_RESET_PROMO_CODE,
		});
	};
};

export const cartSendSrlPointsOTP = (payload, onSuccess, onError) => {
	return (dispatch) => {
		const encryptedPostData = {
			points_info: encryptionStringify(payload, TEMP.SECRET),
		};
		API.post(`/cart/check-available-points`, encryptedPostData)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: CART_SET_SRL_POINTS,
						payload: { points: Number(payload.points), bill_number: res.data.bill_no, redeemed: false },
					});
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: 'Something went wrong! Please try again later' });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const cartReSendSrlPointsOTP = (payload, onSuccess, onError) => {
	return (dispatch) => {
		API.post(`/cart/points-resend-otp`, payload)
			.then((res) => {
				if (res && res.data.status === 1) {
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: 'Something went wrong! Please try again later' });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const cartVerifySrlPointsOTP = (payload, onSuccess, onError) => {
	return (dispatch) => {
		const encryptedPostData = {
			point_otp_info: encryptionStringify(payload, TEMP.SECRET),
		};
		API.post(`/cart/points-confirm-otp`, encryptedPostData)
			.then((res) => {
				if (res && res.data.status === 1) {
					dispatch({
						type: CART_VERIFY_SRL_POINTS,
						payload: { redeem_otp_code: payload.otp_code },
					});
					onSuccess && onSuccess(res.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			})
			.catch((error) => {
				if (error && error.data) {
					onError && onError(error.data);
				} else {
					onError && onError({ message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER });
				}
			});
	};
};

export const cartResetSrlPoints = (onSuccess) => {
	return (dispatch, getState) => {
		const srl_points_redeemed = getState().cart.srl_points_redeemed;
		if (srl_points_redeemed && !isEmpty(srl_points_redeemed)) {
			const postData = {
				bill_no: srl_points_redeemed.bill_number,
			};
			const encryptedPostData = {
				point_otp_info: encryptionStringify(postData, TEMP.SECRET),
			};
			API.post(`/cart/reset-points`, encryptedPostData)
				.then((res) => {
					if (res && res.data.status === 1) {
						dispatch({
							type: CART_RESET_SRL_POINTS,
						});
						onSuccess && onSuccess();
					}
				})
				.catch((error) => {
					// console.log("Cart Reset Points Error",error);
					if (error && error.data) {
						// onError && onError(error.data);
					} else {
						// onError && onError({message: ErrorMessages.SOMETHING_WENT_WRONG_TRY_LATER});
					}
				});
		}
	};
};

export const cartExpireSrlPoints = () => {
	return (dispatch) => {
		dispatch({
			type: CART_EXPIRE_SRL_POINTS,
		});
	};
};

export const setPPTestOption = (pp_data) => {
	return (dispatch) => {
		dispatch({
			type: SET_CART_PP_TEST_OPTION,
			payload: pp_data,
		});
	};
};

export const setHardCopyOption = (isSelected) => {
	return (dispatch) => {
		dispatch({
			type: SET_CART_HARD_COPY_OPTION,
			payload: { isSelected },
		});
	};
};
export const setSampleCollectionType = (payload) => {
	console.log('payload:', payload);
	return (dispatch) => {
		dispatch({
			type: SET_SAMPLE_COLLECTION_TYPE_OPTION,
			payload: payload,
		});
	};
};

export const setCartSelectedLabCenter = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_CART_SELECTED_LAB_CENTER,
			payload: { lab_center: payload },
		});
	};
};

export const setCartCombinedProducts = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_COMBINED_CART_PRODUCTS,
			payload: payload,
		});
	};
};

export const setCartBillDetails = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_CART_BILL_DETAILS,
			payload: payload,
		});
	};
};
