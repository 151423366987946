import {
    SET_ROUTE,
    RESET_ROUTE,
    DESTROY_ROUTE,
    ADD_AUTH_TIMER,
    REMOVE_AUTH_TIMER,
} from '../action-types/action.types';

const initialStates={
    route   :   '',
    timer   :   '',
}

const route = (state = initialStates, action)=>{
    switch (action.type) {
        case SET_ROUTE:{
            return {
                route: action.payload
            }
        }
        case RESET_ROUTE:{
            return {
                ...state,
                route: ''
            }
        }
        // Below Action can be used incase of multiple values in the initial state.
        case DESTROY_ROUTE:{
            return {
                timer: '',
                route: ''
            }
        }
        case ADD_AUTH_TIMER:
            return{
                ...state,
                timer:action.payload,
            }
        case REMOVE_AUTH_TIMER:
            return{
                ...state,
                timer:action.payload,
            }
        default: return state;
    }
       
}

export default route;
